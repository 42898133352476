<template>
    <div class="parent">
      <div class="card-container">
        <h2 class="card-title">Autoevaluación de Aptitudes</h2>
        <p class="card-description">120 Preguntas</p>
        <p class="card-description">Duración Estimada: 120 min</p>
        <button v-if="!isTestCompleteAptitudes" class="card-btn" @click="goToForm('autoevaluacion-aptitudes')">INICIAR</button>
        <button v-else class="card-btn-terminado">TERMINADO</button>
      </div>
  
      <div class="card-container">
        <h2 class="card-title">Test de Holland</h2>
        <p class="card-description">54 Preguntas</p>
        <p class="card-description">Duración Estimada: 60 min</p>
        <button v-if="!isTestCompleteHolland" class="card-btn" @click="goToForm('holland')">INICIAR</button>
        <button v-else class="card-btn-terminado">TERMINADO</button>
      </div>
  
      <div class="card-container">
        <h2 class="card-title">Identificación de Intereses Vocacionales</h2>
        <p class="card-description">80 Preguntas</p>
        <p class="card-description">Duración Estimada: 80 min</p>
        <button v-if="!isTestCompleteVocacionales" class="card-btn" @click="goToForm('identificacion-intereses-vocacionales')">INICIAR</button>
        <button v-else class="card-btn-terminado">TERMINADO</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      isTestCompleteAptitudes() {
        const valor = localStorage.getItem('resultado-aptitudes');
        return valor && valor !== ''; // Devuelve true si existe el valor y no es una cadena vacía
      },
      isTestCompleteHolland() {
        const valor = localStorage.getItem('resultado-holland');
        return valor && valor !== ''; // Devuelve true si existe el valor y no es una cadena vacía
      },
      isTestCompleteVocacionales() {
        const valor = localStorage.getItem('resultado-intereses-vocacionales');
        return valor && valor !== ''; // Devuelve true si existe el valor y no es una cadena vacía
      }
    },
    methods: {
      goToForm(testType) {
        this.$router.push(`/test/${testType}`);
      }
    }
  };
  </script>
  
  <style scoped>
  .parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 0 10px;
    height: 90vh;
    box-sizing: border-box;
  }
  
  .card-container {
    background-color: #f2f2f2;
    border-radius: 10px;
    width: 320px;
    height: 200px;
    display: grid;
    place-items: center;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
  }
  
  .card-title {
    color: #000;
    text-align: center;
  }
  
  .card-description {
    text-align: center;
    font-weight: 600;
  }
  
  .card-btn,
  .card-btn-terminado {
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 250px;
    height: 35px;
    background-color: #0056b3;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .card-btn-terminado {
    opacity: 60%;
  }
  
  /* Estilos responsivos */
  @media (max-width: 1024px) {
    .parent {
      gap: 50px;
    }
  
    .card-container {
      width: 280px;
      height: 180px;
    }
  
    .card-btn,
    .card-btn-terminado {
      width: 220px;
      height: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .parent {
      flex-direction: column;
      gap: 30px;
    }
  
    .card-container {
      width: 100%;
      max-width: 380px;
      height: auto;
    }
  
    .card-btn,
    .card-btn-terminado {
      width: 100%;
    }
  }
  </style>
  