<template>
    <div style="user-select: none;">
        <header class="header" data-header style="background-color: #ffffff;">
            <div class="container">
                <router-link to="/">
                    <h1 style="color:#0D3166;">VocaTecnm</h1>
                </router-link>
            </div>
        </header>

        <div style="user-select: none;">
            <Formulario :initialView="view" />
        </div>

    </div>
</template>

<script>
import Formulario from '@/components/formulario.vue';

export default {
    components: {
        Formulario
    },
    props: {
        view: {
            type: String,
            default: 'signin'
        }
    }
}
</script>

<style scoped>
/* Incluye aquí tus estilos si es necesario */
</style>