<template>
  <link href='https://cdn.jsdelivr.net/npm/boxicons@2.0.5/css/boxicons.min.css' rel='stylesheet'>

  <div class="login">
    <div class="login__content">
      <div class="login__img">
        <img :src="img1" alt="Login Image">
      </div>

      <div class="login__forms">
        <!-- Formulario de inicio de sesión -->
        <form v-if="!isSignUpVisible" class="login__registre">
          <h1 class="login__title">Inicio de Sesión</h1>

          <div class="login__box">
            <i class='bx bx-at login__icon'></i>
            <input type="text" placeholder="Correo electrónico" class="login__input" v-model="loginEmail"
              @input="validateEmail" maxlength="50" />
          </div>

          <span v-if="isErrorVisibleEmail"
            style="text-align: left; color: red; font-weight: 600; font-size: 1.2rem; margin-top: 4%; margin-left: 1%;">
            Correo electrónico inválido
          </span>

          <div class="login__box">
            <i class='bx bx-lock-alt login__icon'></i>
            <input id="passwordInput" type="password" placeholder="Contraseña" v-model="loginPassword"
              @input="validatePassword" class="login__input" />
          </div>

          <span v-if="isErrorVisiblePassword"
            style="text-align: left; color: red; font-weight: 600; font-size: 1.2rem; margin-top: 4%; margin-left: 1%;">
            Contraseña inválida (Mínimo 8 dígitos)
          </span>

          <span v-if="isErrorVisible" style="color: red; font-weight: 600; font-size: 1.2rem; margin-top:4%;">El correo
            electrónico o la contraseña son incorrectos</span>

          <a href="#" class="login__forgot">¿Olvidaste tu contraseña?</a>

          <button type="button" id="login-button" class="login__button" @click="handleLogin"
            :disabled="isRequestPending">
            Iniciar Sesión
          </button>

          <div id="spanshorizontales">
            <span class="login__account">¿No tienes una cuenta?</span>
            <span class="login__signin" @click="toggleForm">Regístrate</span>
          </div>
        </form>

        <!-- Formulario de registro -->
        <form v-if="isSignUpVisible" class="login__create">
          <h1 class="login__title">Registro</h1>

          <div class="login__box">
            <i class='bx bx-at login__icon'></i>
            <input type="text" placeholder="Correo electrónico" class="login__input" v-model="registerEmail"
              @input="validateEmail">
          </div>

          <span v-if="isErrorVisibleEmailRegister"
            style="text-align: left; color: red; font-weight: 600; font-size: 1.2rem; margin-top: 4%; margin-left: 1%;">
            Correo electrónico inválido
          </span>

          <div class="login__box">
            <i class='bx bx-lock-alt login__icon'></i>
            <input type="password" placeholder="Contraseña" class="login__input" v-model="registerPassword"
              @input="validatePassword">
          </div>

          <span v-if="isErrorVisiblePasswordRegister"
            style="text-align: left; color: red; font-weight: 600; font-size: 1.2rem; margin-top: 4%; margin-left: 1%;">
            Contraseña inválida (Mínimo 8 dígitos)
          </span>

          <div class="login__box">
            <i class='bx bxs-graduation login__icon'></i>
            <select id="education-level" v-model="educationLevel" class="login__input">
              <option value="" disabled>Selecciona tu escolaridad</option>
              <option value="Universidad en curso">Universidad en curso</option>
              <option value="Universidad finalizada">Universidad finalizada</option>
              <option value="Preparatoria en curso">Preparatoria en curso</option>
              <option value="Preparatoria finalizada">Preparatoria finalizada</option>
            </select>
          </div>

          <button type="button" id="register-button" class="login__button" @click="handleRegister">
            Crear Cuenta
          </button>


          <div id="spanshorizontales">
            <span class="login__account">¿Ya estás registrado?</span>
            <span class="login__signup" @click="toggleForm">Inicia Sesión</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/img/img-login.svg';
import axios from 'axios';

export default {
  props: {
    initialView: {
      type: String,
      default: 'signin', // 'signin' o 'signup'
    }
  },
  data() {
    return {
      img1,
      isSignUpVisible: this.initialView === 'signup',
      loginEmail: '',
      loginPassword: '',
      registerEmail: '',
      registerPassword: '',
      educationLevel: '',
      isRequestPending: false,
      isErrorVisible: false,
      isErrorVisibleEmail: false,
      isErrorVisiblePassword: false,
      isErrorVisibleEmailRegister: false,
      isErrorVisiblePasswordRegister: false
    };
  },
  methods: {

    toggleForm() {
      this.isSignUpVisible = !this.isSignUpVisible;
    },

    validateEmail() {
      const allowedCharactersPattern = /^[a-zA-Z0-9._@-]*$/;
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!allowedCharactersPattern.test(this.loginEmail)) {
        this.loginEmail = this.loginEmail.replace(/[^a-zA-Z0-9._@-]/g, '');
      }
      if (!allowedCharactersPattern.test(this.registerEmail)) {
        this.registerEmail = this.registerEmail.replace(/[^a-zA-Z0-9._@-]/g, '');
      }

      // Si quieres aplicar el emailPattern al final:
      if (!emailPattern.test(this.loginEmail)) {
        // Mostrar mensaje de error o aplicar acciones adicionales
      }
      if (!emailPattern.test(this.registerEmail)) {
        // Mostrar mensaje de error o aplicar acciones adicionales
      }
    },
    validatePassword() {
      const passwordPattern = /[^a-zA-Z0-9@#%*!&$]/g;
      this.loginPassword = this.loginPassword.replace(passwordPattern, '');
      this.registerPassword = this.registerPassword.replace(passwordPattern, '');
    },

    //Peticiones
    async handleLogin() {
      if (this.isRequestPending) return;
      this.isRequestPending = true;

      // Validación de contenido en campos
      if (this.loginEmail && this.loginPassword) {

        // Validación de correo
        if (this.loginEmail.includes('@') && this.loginEmail.length > 10) {
          // Validación de contraseña
          if (this.loginPassword.length > 8) {
            try {
              const response = await axios.post('https://www.vocatecnm-api.somee.com/usuarios/login', {
                correo: this.loginEmail,
                contraseña: this.loginPassword,
              });


              localStorage.setItem('usuario', response.data.result.idUsuario);
              localStorage.setItem('token', response.data.result.token);

              // Eliminación de LS después de 8 horas
              setTimeout(() => {
                localStorage.removeItem('usuario');
                localStorage.removeItem('token');
                window.location.reload();
              }, 28800000);

              // Redireccionamiento de ruta
              const redirectUrl = this.$route.query.redirect || '/test';
              this.$router.push(redirectUrl);

            } catch (error) {
              console.error('Login Error:', error.response ? error.response.data : error.message); // Log de error
              this.isErrorVisible = true;
              setTimeout(() => {
                this.isErrorVisible = false;
              }, 5000);
            } finally {
              this.isRequestPending = false;
            }
          } else {
            // Mostrar error de contraseña
            this.isErrorVisiblePassword = true;
            setTimeout(() => {
              this.isErrorVisiblePassword = false;
            }, 5000);
          }
        } else {
          // Mostrar error de email
          this.isErrorVisibleEmail = true;
          setTimeout(() => {
            this.isErrorVisibleEmail = false;
          }, 5000);
        }
      } else {
        console.warn('Campos de email o contraseña vacíos'); // Log de advertencia
      }
    }

    ,

    async handleRegister(event) {
      event.preventDefault(); // Prevenir comportamiento predeterminado
      if (this.isRequestPending) return;
      this.isRequestPending = true;

      // Validación de contenido en campos
      if (this.registerEmail && this.registerPassword && this.educationLevel) {
        if (this.registerEmail.includes('@') && this.registerEmail.length > 8) {
          if (this.registerPassword.length > 8) {
            try {
              const response = await axios.post('https://www.vocatecnm-api.somee.com/usuarios/registro', {
                correo: this.registerEmail,
                contraseña: this.registerPassword,
                ocupacion: this.educationLevel,
              }, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });

              if (response.data) {
                localStorage.setItem('usuario', response.data.result.idUsuario);
                localStorage.setItem('token', response.data.result.token);

                // Eliminación de LS después de 8 horas
                setTimeout(() => {
                  localStorage.removeItem('usuario');
                  localStorage.removeItem('token');
                  window.location.reload();
                }, 28800000);

                // Redireccionamiento de ruta
                const redirectUrl = this.$route.query.redirect || '/test';
                this.$router.push(redirectUrl);
              }

            } catch (error) {
              console.error('Register Error:', error.response ? error.response.data : error.message); // Log de error
            } finally {
              this.isRequestPending = false;
            }
          } else {
            // Mostrar error de contraseña
            this.isErrorVisiblePasswordRegister = true;
            setTimeout(() => {
              this.isErrorVisiblePasswordRegister = false;
            }, 5000);
          }
        } else {
          // Mostrar error de email
          this.isErrorVisibleEmailRegister = true;
          setTimeout(() => {
            this.isErrorVisibleEmailRegister = false;
          }, 5000);
        }
      } else {
        console.warn('Campos de email, contraseña o ocupación vacíos'); // Log de advertencia
      }
    },



    toggleForm() {
      this.isSignUpVisible = !this.isSignUpVisible;
    }
  }
}
</script>

<style scoped>
#spanshorizontales {
  display: flex;
  justify-content: center;
  gap: .500rem;
}

/*===== VARIABLES CSS =====*/
:root {
  /*===== Colores =====*/
  --first-color: #0D3166;
  --first-color-dark: #0a2347;
  --first-color-light: #A49EAC;
  --first-color-lighten: #F2F2F2;

  /*===== Font and typography =====*/
  --body-font: sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
}

@media screen and (min-width: 768px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: .938rem;
  color: #0a2347;
}

h1 {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*===== LOGIN =====*/
.login {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.login__content {
  display: grid;
}

.login__img {
  justify-self: center;
}

.login__img img {
  width: 310px;
  margin-top: 1.5rem;
}

.login__forms {
  position: relative;
  height: 368px;
}

.login__registre,
.login__create {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  background-color: #F2F2F2;

  padding: 3.5rem 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(35, 0, 77, .2);
  animation-duration: .4s;
  animation-name: animate-login;
}

@keyframes animate-login {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.login__title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #0D3166;
}

.login__box {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: .5rem;
  padding: 1.125rem 1rem;
  background-color: #FFF;
  margin-top: 1rem;
  border-radius: .5rem;
}

.login__icon {
  font-size: 2.3rem;
  color: #0D3166;
}

.login__input {
  border: none;
  outline: none;
  font-size: 1.6rem;
  font-weight: 700;
  color: #0a2347;
}

.login__input::placeholder {
  outline: none;
  font-size: 1.6;
  font-family: var(--body-font);
  color: #A49EAC;
}

.login__forgot {
  display: block;
  width: max-content;
  margin-left: auto;
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #A49EAC;
}

.login__button {
  width: 100%;
  display: block;
  padding: 1rem;
  margin: 2rem 0;
  background-color: #0D3166;
  color: #FFF;
  font-weight: 600;
  text-align: center;
  border-radius: .5rem;
  transition: .3s;
  font-size: 1.6rem;
}

.login__button:hover {
  background-color: #0a2347;
}

.login__account,
.login__signin,
.login__signup {
  font-weight: 600;
  font-size: 1.4rem;

}

.login__account {
  color: #0a2347;
}

.login__signin,
.login__signup {
  color: var(--first-color);
  cursor: pointer;
}

.login__social {
  margin-top: 2rem;
}

.login__social-icon {
  font-size: 1.5rem;
  color: #0a2347;
  margin: 0 1.5rem;
}

/*Show login*/
.block {
  display: block;
}

/*Hidden login*/
.none {
  display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
  .login__forms {
    width: 348px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px) {
  .login {
    height: 100vh;
    overflow: hidden;
  }

  .login__content {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
  }

  .login__img {
    display: flex;
    width: 600px;
    height: 588px;
    background-color: #F2F2F2;
    border-radius: 1rem;
    padding-left: 1rem;
  }

  .login__img img {
    width: 390px;
    margin-top: 0;
  }

  .login__registre,
  .login__create {
    left: -11rem;
  }

  .login__registre {
    bottom: -2rem;
  }

  .login__create {
    bottom: -5.5rem;
  }
}
</style>