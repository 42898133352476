<template>
    <div class="content-principal">
        <div class="quiz-container">
            <div v-if="currentQuestionIndex < questions.length">
                <h2 class="question-text">{{ questions[currentQuestionIndex].questionText }}</h2>
                <div style="display: flex;">
                    <div v-for="(answer, index) in questions[currentQuestionIndex].answerOptions" :key="index"
                        class="answer-option">
                        <label :for="`answer-${index}`" class="answer-label">
                            <input type="radio" :id="`answer-${index}`" :name="'question-' + currentQuestionIndex"
                                :value="answer.answerText" v-model="selectedAnswers[currentQuestionIndex]" />
                            {{ answer.answerText }}
                        </label>
                    </div>
                </div>
                <div class="navigation-buttons">
                    <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">Previous</button>
                    <button v-if="!isLastQuestion || !allQuestionsAnswered" @click="nextQuestion">Next</button>
                    <button v-else @click="submitAnswers">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentQuestionIndex: 0,
            maxScore: 40, // Puntuación máxima posible
            correctScore: 0, //Preguntas acertadas
            calificacionPorcentaje: 0, //Calificacion final
            selectedAnswers: [],
            correctAnswersSelected: [], // Nueva variable para almacenar las respuestas correctas seleccionadas
            questions: [
                {
                    questionText: 'Sume: 393 ➕ 4658 ➕ 3790 ➕ 67',
                    answerOptions: [
                        { answerText: 'A) 7908', isCorrect: false },
                        { answerText: 'B) 8608', isCorrect: false },
                        { answerText: 'C) 8898', isCorrect: false },
                        { answerText: 'D) 8908', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Reste: 5473 ➖ 2987',
                    answerOptions: [
                        { answerText: 'A) 2485', isCorrect: false },
                        { answerText: 'B) 2486', isCorrect: true },
                        { answerText: 'C) 2496', isCorrect: false },
                        { answerText: 'D) 3486', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Multiplique: 484 ✖️ 25',
                    answerOptions: [
                        { answerText: 'A) 10900', isCorrect: false },
                        { answerText: 'B) 11100', isCorrect: false },
                        { answerText: 'C) 11900', isCorrect: false },
                        { answerText: 'D) 11700', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: true },
                    ],
                },
                {
                    questionText: 'Multiplique: 2.04 ✖️ .75',
                    answerOptions: [
                        { answerText: 'A) 1.5300', isCorrect: false },
                        { answerText: 'B) 153.0', isCorrect: true },
                        { answerText: 'C) 1530', isCorrect: false },
                        { answerText: 'D) 15300', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Multiplique: 4.50 ✖️ 22',
                    answerOptions: [
                        { answerText: 'A) .99', isCorrect: false },
                        { answerText: 'B) 98.40', isCorrect: false },
                        { answerText: 'C) 99.00', isCorrect: true },
                        { answerText: 'D) 9900', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Multiplique: .025 ✖️ .025',
                    answerOptions: [
                        { answerText: 'A) .001375', isCorrect: false },
                        { answerText: 'B) .00625', isCorrect: false },
                        { answerText: 'C) .625', isCorrect: false },
                        { answerText: 'D) 1.375', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: true },
                    ],
                },
                {
                    questionText: 'Multiplique: .016 ✖️ .016',
                    answerOptions: [
                        { answerText: 'A) 256', isCorrect: false },
                        { answerText: 'B) 25.6', isCorrect: false },
                        { answerText: 'C) .00256', isCorrect: false },
                        { answerText: 'D) .000256', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Divida: 69 ➗ 46',
                    answerOptions: [
                        { answerText: 'A) 1 13/46', isCorrect: false },
                        { answerText: 'B) 1 23/46', isCorrect: false },
                        { answerText: 'C) 1.5', isCorrect: true },
                        { answerText: 'D) 15', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Divida: 2.25 ➗ .75',
                    answerOptions: [
                        { answerText: 'A) .0003', isCorrect: false },
                        { answerText: 'B) .03', isCorrect: false },
                        { answerText: 'C) .3', isCorrect: false },
                        { answerText: 'D) 3', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Divida: .72 ➗ 3.6',
                    answerOptions: [
                        { answerText: 'A) .02', isCorrect: false },
                        { answerText: 'B) .2', isCorrect: true },
                        { answerText: 'C) 2', isCorrect: false },
                        { answerText: 'D) 20', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Divida: 304.09 ➗ 64.7',
                    answerOptions: [
                        { answerText: 'A) .47', isCorrect: false },
                        { answerText: 'B) 4.07', isCorrect: false },
                        { answerText: 'C) 4.7', isCorrect: true },
                        { answerText: 'D) 47', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Divida: 4.036 ➗ .04',
                    answerOptions: [
                        { answerText: 'A) 1.009', isCorrect: false },
                        { answerText: 'B) 10.9', isCorrect: false },
                        { answerText: 'C) 10.09', isCorrect: false },
                        { answerText: 'D) 100.9', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Fracciones: (1/4) ➗ (1/8): nota, los resultados de ser posible deben de ser simplificados',
                    answerOptions: [
                        { answerText: 'A) 1/32', isCorrect: false },
                        { answerText: 'B) 1/8', isCorrect: false },
                        { answerText: 'C) 1/2', isCorrect: false },
                        { answerText: 'D) 2', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Fracciones: (2/7) ✖️ (3/7)',
                    answerOptions: [
                        { answerText: 'A) 6/49', isCorrect: true },
                        { answerText: 'B) 3/7', isCorrect: false },
                        { answerText: 'C) 2/3', isCorrect: false },
                        { answerText: 'D) 6/7', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Fracciones: (3 ✖️ 10) ➗ (5 ✖️ 9)',
                    answerOptions: [
                        { answerText: 'A) 6/49', isCorrect: false },
                        { answerText: 'B) 3/7', isCorrect: false },
                        { answerText: 'C) 2/3', isCorrect: true },
                        { answerText: 'D) 6/7', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Suma: (4 3/4) ➕ (9 1/2) ➕ (13 7/8)',
                    answerOptions: [
                        { answerText: 'A) 26 11/14', isCorrect: false },
                        { answerText: 'B) 27 1/8', isCorrect: false },
                        { answerText: 'C) 28 1/2', isCorrect: false },
                        { answerText: 'D) 28 11/14', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: true },
                    ],
                },
                {
                    questionText: 'Suma: 2 pies ➕ 3 pies ➕ 28 pies ➕ 17 pies ➕ 11 1/2 pulgadas ➕ 5 pulgadas ➕ 4 1/2 pulgadas',
                    answerOptions: [
                        { answerText: 'A) 49 pies', isCorrect: false },
                        { answerText: 'B) 48 pies y 2 pulgadas', isCorrect: false },
                        { answerText: 'C) 47 pies y 24 pulgadas', isCorrect: false },
                        { answerText: 'D) 48 pies', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: true },
                    ],
                },
                {
                    questionText: 'Suma: 3 lbs ➕ 6 lbs ➕ 7 lbs ➕ 11 lbs ➕ 3 oz ➕ 7 oz ➕ 5 oz ➕ 1 oz',
                    answerOptions: [
                        { answerText: 'A) 28 lbs. 16 oz', isCorrect: false },
                        { answerText: 'B) 28 lbs', isCorrect: false },
                        { answerText: 'C) 27 lbs. 16 oz', isCorrect: true },
                        { answerText: 'D) 18 lbs', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Raíz cuadrada: √169',
                    answerOptions: [
                        { answerText: 'A) 13', isCorrect: true },
                        { answerText: 'B) 43', isCorrect: false },
                        { answerText: 'C) 84 1/2', isCorrect: false },
                        { answerText: 'D) 169', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Raíz cuadrada: √.09',
                    answerOptions: [
                        { answerText: 'A) .03', isCorrect: false },
                        { answerText: 'B) .3', isCorrect: true },
                        { answerText: 'C) 3', isCorrect: false },
                        { answerText: 'D) 9', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Raíz cuadrada: √4/9 ✖️ 25/36',
                    answerOptions: [
                        { answerText: 'A) 25/81', isCorrect: false },
                        { answerText: 'B) 25/36', isCorrect: false },
                        { answerText: 'C) 5/9', isCorrect: true },
                        { answerText: 'D) 2 7/9', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'x = 33 1/3 % de 963',
                    answerOptions: [
                        { answerText: 'A) 32.19', isCorrect: false },
                        { answerText: 'B) 231', isCorrect: false },
                        { answerText: 'C) 321', isCorrect: true },
                        { answerText: 'D) 32100', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'x = 12 1/2 % de 816',
                    answerOptions: [
                        { answerText: 'A) .12', isCorrect: false },
                        { answerText: 'B) 12', isCorrect: false },
                        { answerText: 'C) 102', isCorrect: true },
                        { answerText: 'D) 104', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'x = 4/9 % de 648',
                    answerOptions: [
                        { answerText: 'A) 14.58', isCorrect: false },
                        { answerText: 'B) 72', isCorrect: false },
                        { answerText: 'C) 218', isCorrect: false },
                        { answerText: 'D) 1458', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: true },
                    ],
                },
                {
                    questionText: '15 = 75% de x',
                    answerOptions: [
                        { answerText: 'A) .20', isCorrect: false },
                        { answerText: 'B) 10.25', isCorrect: false },
                        { answerText: 'C) 20', isCorrect: true },
                        { answerText: 'D) 22.5', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '25 = x % de 125',
                    answerOptions: [
                        { answerText: 'A) 1/5', isCorrect: false },
                        { answerText: 'B) 5', isCorrect: false },
                        { answerText: 'C) 20', isCorrect: true },
                        { answerText: 'D) 31.25', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '2.5 = x % de 2',
                    answerOptions: [
                        { answerText: 'A) 5', isCorrect: false },
                        { answerText: 'B) 8', isCorrect: false },
                        { answerText: 'C) 80', isCorrect: false },
                        { answerText: 'D) 125', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'x/8 = 3/24',
                    answerOptions: [
                        { answerText: 'A) 1;8', isCorrect: false },
                        { answerText: 'B) 1', isCorrect: true },
                        { answerText: 'C) 3', isCorrect: false },
                        { answerText: 'D) 4', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '5/9 = 55/x',
                    answerOptions: [
                        { answerText: 'A) 55/99', isCorrect: false },
                        { answerText: 'B) 11', isCorrect: false },
                        { answerText: 'C) 45', isCorrect: false },
                        { answerText: 'D) 99', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '11/4 = 77/x',
                    answerOptions: [
                        { answerText: 'A) 77/28', isCorrect: false },
                        { answerText: 'B) 28', isCorrect: true },
                        { answerText: 'C) 44', isCorrect: false },
                        { answerText: 'D) 308', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Raíz cúbica de 32 ✖️ 2',
                    answerOptions: [
                        { answerText: 'A) 4', isCorrect: true },
                        { answerText: 'B) 8', isCorrect: false },
                        { answerText: 'C) 21 1/3', isCorrect: false },
                        { answerText: 'D) 192', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Raíz cúbica de .000729',
                    answerOptions: [
                        { answerText: 'A) .000243', isCorrect: false },
                        { answerText: 'B) .009', isCorrect: false },
                        { answerText: 'C) .027', isCorrect: false },
                        { answerText: 'D) .09', isCorrect: true },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Raíz cúbica de (1/8) ✖️ (125/64)',
                    answerOptions: [
                        { answerText: 'A) 5/8', isCorrect: true },
                        { answerText: 'B) 375/512', isCorrect: false },
                        { answerText: 'C) 2 1/2', isCorrect: false },
                        { answerText: 'D) 15 5/8', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: 'Precio de catálogo = Q 75.00. Descuentos sucesivos = 33 1/3 %;2%. Precio de venta = Q',
                    answerOptions: [
                        { answerText: 'A) 25', isCorrect: true },
                        { answerText: 'B) 48.50', isCorrect: false },
                        { answerText: 'C) 49.50', isCorrect: false },
                        { answerText: 'D) 50', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '¿Qué número puede sustituir a las dos incognitas? 2/x = x/50',
                    answerOptions: [
                        { answerText: 'A) 1', isCorrect: false },
                        { answerText: 'B) 10', isCorrect: true },
                        { answerText: 'C) 25', isCorrect: false },
                        { answerText: 'D) 100', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '¿Qué número puede sustituir a las dos incognitas? 1/x = x/36',
                    answerOptions: [
                        { answerText: 'A) 6', isCorrect: true },
                        { answerText: 'B) 12', isCorrect: false },
                        { answerText: 'C) 35', isCorrect: false },
                        { answerText: 'D) 36', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '¿Qué número puede sustituir a las dos incognitas? 4/x = x/100',
                    answerOptions: [
                        { answerText: 'A) 1', isCorrect: false },
                        { answerText: 'B) 20', isCorrect: true },
                        { answerText: 'C) 25', isCorrect: false },
                        { answerText: 'D) 200', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '¿Qué número puede sustituir a las dos incognitas? 8/x = x/(12 1/2)',
                    answerOptions: [
                        { answerText: 'A) 1 1/2', isCorrect: false },
                        { answerText: 'B) 4', isCorrect: false },
                        { answerText: 'C) 64', isCorrect: false },
                        { answerText: 'D) 100', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: true },
                    ],
                },
                {
                    questionText: '¿Qué número puede sustituir a las dos incognitas? 6.25/x = x/16',
                    answerOptions: [
                        { answerText: 'A) 4', isCorrect: false },
                        { answerText: 'B) 10', isCorrect: true },
                        { answerText: 'C) 16', isCorrect: false },
                        { answerText: 'D) 50', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                },
                {
                    questionText: '( 9 ➕ 1 ✖️ 6 ➖ 3) ➗ ( 4 ➕ 2 ✖️ 7 ➖ 6 )',
                    answerOptions: [
                        { answerText: 'A) 57/50', isCorrect: false },
                        { answerText: 'B) 1 7/12', isCorrect: false },
                        { answerText: 'C) 1', isCorrect: true },
                        { answerText: 'D) 57/36', isCorrect: false },
                        { answerText: 'E) Ninguna de éstas', isCorrect: false },
                    ],
                }
            ],
        };
    },
    methods: {
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
            }
        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        },
        async submitAnswers() {
            let usuario = localStorage.getItem('usuario');

            // Limpiamos el array de respuestas correctas seleccionadas
            this.correctAnswersSelected = [];

            // Recorremos las respuestas seleccionadas y verificamos si son correctas
            this.selectedAnswers.forEach((selectedAnswer, questionIndex) => {
                const correctAnswer = this.questions[questionIndex].answerOptions.find(
                    (answer) => answer.answerText === selectedAnswer && answer.isCorrect
                );

                // Si es correcta, la agregamos al array
                if (correctAnswer) {
                    this.correctAnswersSelected.push(correctAnswer);
                }
            });

            this.correctScore = this.correctAnswersSelected.length;
            this.calificacionPorcentaje = ((this.correctScore / this.maxScore) * 100).toFixed(1);

            const data = {
                idResultado: 0,
                idUsuario: usuario,
                resultadoTestMatematico: this.calificacionPorcentaje.toString(),
                resultadoTestEspanol: "",
                resultadoTest1: { content: "" },
                resultadoTest2: { content: "" },
                resultadoTest3: { content: "" }
            };

            try {
                const response = await axios.post('https://www.vocatecnm-api.somee.com/resultado', data);
                // Manejar respuesta exitosa
                localStorage.removeItem('resultado-matematico');
                localStorage.setItem('resultado-matematico', response.data.resultadoTestMatematico);
                // Redireccionamiento de ruta
                const redirectUrl = this.$route.query.redirect || '/test/academico';
                this.$router.push(redirectUrl);

            } catch (error) {
                console.error('Error:', error);
                // Manejar error
            }


        },
    },
    computed: {
        isLastQuestion() {
            return this.currentQuestionIndex === this.questions.length - 1;
        },
        allQuestionsAnswered() {
            return this.selectedAnswers.length === this.questions.length;
        },
    },

};
</script>

<style scoped>
.content-principal {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    /* Centra verticalmente */
    justify-content: center;
    /* Centra horizontalmente */
}

.quiz-container {
    padding: 20px 20px;
    /* Ajusta el espaciado interno */
    display: flex;
    flex-direction: column;
    /* Alinea los elementos en columna */
    align-items: center;
    /* Centra horizontalmente */
    justify-content: center;
    /* Centra verticalmente */
    margin-top: 100px;
    /* Ajusta el margen superior */
    background-color: #F2F2F2;
    border-radius: 10px;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    width: 900px;
    /* Ajusta el ancho */
    height: 250px;
}

.question-text {
    margin-bottom: 16px;
    font-size: 18px;
    /* Tamaño de fuente de la pregunta */
    font-weight: bold;
    /* Negrita para la pregunta */
    text-align: center;
    /* Centra el texto de la pregunta */
}

.answer-option {
    display: flex;
    /* Coloca el label y el input en una fila */
    align-items: center;
    /* Alinea verticalmente los elementos dentro del contenedor */
    margin-bottom: 12px;
    /* Espaciado entre las opciones de respuesta */
}

.answer-label {
    display: flex;
    /* Coloca el input y el texto en una fila dentro del label */
    align-items: center;
    /* Alinea verticalmente el input y el texto */
    margin-right: 20px;
    /* Espaciado entre cada conjunto de radio button y texto */
    white-space: nowrap;
    /* Evita que el texto se ajuste y haga saltos de línea */
    overflow: hidden;
    /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis;
    /* Añade puntos suspensivos si el texto es demasiado largo */
}

input[type="radio"] {
    margin-right: 8px;
    /* Espaciado entre el radio button y el texto */
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    /* Espaciado superior para los botones de navegación */
    gap: 12px;
    /* Espaciado entre los botones */
}

.navigation-buttons button {
    background-color: #007BFF;
    /* Color de fondo */
    color: #fff;
    /* Color del texto */
    border: none;
    /* Sin borde */
    border-radius: 5px;
    /* Bordes redondeados */
    padding: 10px 20px;
    /* Espaciado interno */
    font-size: 16px;
    /* Tamaño del texto */
    cursor: pointer;
    /* Cursor de puntero */
    transition: background-color 0.3s;
    /* Transición para el cambio de color */
}

.navigation-buttons button:hover {
    background-color: #0056b3;
    /* Color de fondo en hover */
}

.navigation-buttons button:disabled {
    background-color: #d6d6d6;
    /* Color de fondo para botón deshabilitado */
    cursor: not-allowed;
    /* Cursor de no permitido */
}
</style>
