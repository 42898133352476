<template>
    <div class="parent">
        <div class="card-container">
            <h2 class='card-title'>Test de Habilidad Numérica</h2>
            <p class='card-description'>40 Preguntas</p>
            <p class='card-description'>Duración Estimada: 15 min</p>
            <button v-if="!isTestCompleteMatematica" class="card-btn" @click="goToForm('habilidad-numerica')">INICIAR</button>
            <button v-else class="card-btn-terminado">TERMINADO</button>
        </div>
        
        <div class="card-container">
            <h2 class='card-title'>Test de Comprensión Lectora</h2>
            <p class='card-description'>50 Preguntas</p>
            <p class='card-description'>Duración Estimada: 30 min</p>
            <button v-if="!isTestCompleteLectora" class="card-btn" @click="goToForm('habilidad-lectora')">INICIAR</button>
            <button v-else class="card-btn-terminado">TERMINADO</button>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        isTestCompleteMatematica() {
            const valor = localStorage.getItem('resultado-matematico');
            return valor !== null;
        },
        isTestCompleteLectora() {
            const valor = localStorage.getItem('resultado-lectora');
            return valor !== null;
        }
    },
    methods: {
        goToForm(testType) {
            this.$router.push(`/test/${testType}`);
        }
    }
}
</script>

<style scoped>
.parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding: 0 20px; /* Espacio lateral para evitar que las tarjetas se peguen al borde */
}

.card-container {
    background-color: #F2F2F2;
    border-radius: 10px;
    width: 380px;
    height: 200px;
    display: grid;
    place-items: center;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    margin: 0 auto; /* Asegura que las tarjetas estén centradas y tengan el mismo espacio a ambos lados */
}

.card-title {
    color: #000;
    text-align: center;
}

.card-description {
    text-align: center;
    font-weight: 600;
}

.card-btn, .card-btn-terminado {
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 250px;
    height: 35px;
    background-color: #0056b3;
    margin-bottom: 10px;
    font-weight: 600;
}

.card-btn-terminado {
    opacity: 60%;
}

/* Estilos responsivos */
@media (max-width: 1024px) {
    .parent {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 0 15px; /* Ajusta el espacio lateral en pantallas medianas */
    }

    .card-container {
        width: 300px;
        height: 180px;
    }

    .card-btn, .card-btn-terminado {
        width: 220px;
        height: 30px;
    }
}

@media (max-width: 768px) {
    .parent {
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 0 10px; /* Ajusta el espacio lateral en pantallas pequeñas */
    }

    .card-container {
        width: 100%; /* Asegura que la tarjeta ocupe todo el ancho disponible */
        max-width: 380px; /* Limita el ancho máximo para que no se expanda demasiado */
        height: auto;
    }

    .card-btn, .card-btn-terminado {
        width: 100%; /* Asegura que los botones ocupen el ancho completo del contenedor */
    }
}
</style>
