<template>
    <div class="content-principal">
        <div class="quiz-container">
            <div v-if="currentQuestionIndex < questions.length">
                <h2 class="question-text">{{ questions[currentQuestionIndex].questionText }}</h2>
                <div style="display: flex;">
                    <div v-for="(answer, index) in questions[currentQuestionIndex].answerOptions" :key="index"
                        class="answer-option">
                        <label :for="`answer-${index}`" class="answer-label">
                            <input type="radio" :id="`answer-${index}`" :name="'question-' + currentQuestionIndex"
                                :value="answer.answerScore" v-model="selectedAnswers[currentQuestionIndex]" />
                            {{ answer.answerText }}
                        </label>
                    </div>
                </div>
                <div class="navigation-buttons">
                    <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">Previous</button>
                    <button v-if="!isLastQuestion || !allQuestionsAnswered" @click="nextQuestion">Next</button>
                    <button v-else @click="submitAnswers">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentQuestionIndex: 0,
            selectedAnswers: [],
            questions: [
                {
                    questionText: "Diseñar programas de computación y explorar nuevas aplicaciones tecnológicas para uso del internet.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Criar, cuidar y tratar animales domésticos y de campo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Investigar sobre áreas verdes, medio ambiente y cambios climáticos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Ilustrar, dibujar y animar digitalmente.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Seleccionar, capacitar y motivar al personal de una organización/empresa.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Realizar excavaciones para descubrir restos del pasado.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Resolver problemas de cálculo para construir un puente.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar cursos para enseñar a la gente sobre temas de salud e higiene.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Tocar un instrumento y componer música.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Planificar cuáles son las metas de una organización pública o privada a mediano y largo plazo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar y planificar la producción masiva de artículos como muebles, autos, equipos de oficina, empaques y envases para alimentos y otros.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar logotipos y portadas de una revista.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Organizar eventos y atender a sus asistentes.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Atender la salud de personas enfermas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Controlar ingresos y egresos de fondos y presentar el balance final de una institución.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Hacer experimentos con plantas (frutas, árboles, flores).",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Concebir planos para viviendas, edificios y ciudadelas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Investigar y probar nuevos productos farmacéuticos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Hacer propuestas y formular estrategias para aprovechar las relaciones económicas entre dos países.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Pintar, hacer esculturas, ilustrar libros de arte, etcétera.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Elaborar campañas para introducir un nuevo producto al mercado.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Examinar y tratar los problemas visuales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Defender a clientes individuales o empresas en juicios de diferente naturaleza.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar máquinas que puedan simular actividades humanas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Investigar las causas y efectos de los trastornos emocionales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Supervisar las ventas de un centro comercial.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Atender y realizar ejercicios a personas que tienen limitaciones físicas, problemas de lenguaje, etcétera.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prepararse para ser modelo profesional.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Aconsejar a las personas sobre planes de ahorro e inversiones.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Elaborar mapas, planos e imágenes para el estudio y análisis de datos geográficos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar juegos interactivos electrónicos para computadora.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Realizar el control de calidad de los alimentos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Tener un negocio propio de tipo comercial.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Escribir artículos periodísticos, cuentos, novelas y otros.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Redactar guiones y libretos para un programa de televisión.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Organizar un plan de distribución y venta de un gran almacén.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Estudiar la diversidad cultural en el ámbito rural y urbano.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Gestionar y evaluar convenios internacionales de cooperación para el desarrollo social.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Crear campañas publicitarias.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Trabajar investigando la reproducción de peces, camarones y otros animales marinos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Dedicarse a fabricar productos alimenticios de consumo masivo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Gestionar y evaluar proyectos de desarrollo en una institución educativa y/o fundación.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Rediseñar y decorar espacios físicos en viviendas, oficinas y locales comerciales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Administrar una empresa de turismo y/o agencias de viaje.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Aplicar métodos alternativos a la medicina tradicional para atender personas con dolencias de diversa índole.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar ropa para niños, jóvenes y adultos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Investigar organismos vivos para elaborar vacunas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Manejar y/o dar mantenimiento a dispositivos/aparatos tecnológicos en aviones, barcos, radares, etcétera.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Estudiar idiomas extranjeros actuales y antiguos para hacer traducción.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Restaurar piezas y obras de arte.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Revisar y dar mantenimiento a artefactos eléctricos, electrónicos y computadoras.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Enseñar a niños de 0 a 5 años.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Investigar y/o sondear nuevos mercados.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Atender la salud dental de las personas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Tratar a niños, jóvenes y adultos con problemas psicológicos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Crear estrategias de promoción y venta de nuevos productos ecuatorianos en el mercado internacional.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Planificar y recomendar dietas para personas diabéticas y/o con sobrepeso.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Trabajar en una empresa petrolera en un cargo técnico como control de la producción.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Administrar una empresa (familiar, privada o pública).",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Tener un taller de reparación y mantenimiento de carros, tractores, etcétera.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Ejecutar proyectos de extracción minera y metalúrgica.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Asistir a directivos de multinacionales con manejo de varios idiomas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar programas educativos para niños con discapacidad.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Aplicar conocimientos de estadística en investigaciones en diversas áreas (social, administrativa, salud, etcétera.).",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Fotografiar hechos históricos, lugares significativos, rostros, paisajes para el área publicitaria, artística, periodística y social.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Trabajar en museos y bibliotecas nacionales e internacionales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Ser parte de un grupo de teatro.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Producir cortometrajes, spots publicitarios, programas educativos, de ficción, etcétera.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Estudiar la influencia entre las corrientes marinas y el clima y sus consecuencias ecológicas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Conocer las distintas religiones, su filosofía y transmitirlas a la comunidad en general.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Asesorar a inversionistas en la compra de bienes/acciones en mercados nacionales e internacionales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Estudiar grupos étnicos, sus costumbres, tradiciones, cultura y compartir sus vivencias.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Explorar el espacio sideral, los planetas, características y componentes.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Mejorar la imagen facial y corporal de las personas aplicando diferentes técnicas. 75 Decorar jardines de casas y parques públicos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Decorar jardines de casas y parques públicos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Administrar y renovar menues de comidas en un hotel a restaurante.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Trabajar como presentador de televisión, locutor de radio y televisión, animador de programas culturales y concursos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar y ejecutar programas de turismo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Administrar y ordenar (planificar) adecuadamente la ocupación del espacio fiskco de ciudades, paises etc., utilizando imágenes de satélite, mapas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Organizar, planificar y administrar centros educativos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar proyectos urbano-arquitectónicos de manera integral,para crear el hábitad humano.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar y establecer los procesos de fabricación de componentes de avión.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Elaborar, implementar y mantener sistemas degestión ambiental.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar e implementar instrumental para diagnostico médico, tratamiento y rehabilitación integral de pacientes.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar, seleccionar y evaluar equipos y procesos para elaprovechamiento sustentable de los recursos bióticos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar y optimizar los procesos de producción para diferentes industrias como la farmacéutica, alimentaria y la petroquímica.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Edificar unidades habitacionales, comerciales, industriales, públicas y de servicio.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Simular modelos que permitan predecir el comportamiento de sistemas electrónicos empleando plataformas computacionales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar e implmentar interfaces hombre-máquina y máquina-máquina para la automatización de sistemas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar, seleccionar, operar, optimizar y controlar procesos en industrias de servicios con base en el desarrollo tecnológico.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar, desarrollar y construir un prototipo mecatrónico (físico y/o virtual).",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Desarrollo 3D asistidos por computadoras (CAD) para el desarrollo de herramientas y accesorios.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Analizar, diseñar y gestionar sistemas productivos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar, implementar y administrar redes de cómputo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Manipular materiales a escalas atomicas y moleculares.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Formular, desarrollar y gestionar proyectos de software.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar el interiorismo y paisajismo para crear ambientes confortables y funcionales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Establecer programas de gestión de la calidad aeroespacial.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Proponer e innovar tecnologías para el manejo de los residuos cumpliendo la legislación vigente.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Inovar, integrar y administrar la tecnología en laboratorio, clínicas y hospitales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Procesos de fermentación o en general procesamiento de materiales de origen biológico, farmacéuticos y de la salud, alimentarios.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Planeareimplementar sistemas de gestión de calidad, ambientae higiene y seguridad en distintos sectores.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar un proyecto de automatización aplicando sistemas eléctricos, electroneumáticos o electrohidráulicos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar, implementar y mejorar sistemas y estaciones de trabajo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Diseñar, desaroolar  y gestionar sistemas de bases de datos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Sintetizar y caracterizar nanomateriales, así como dispositivos nanoestructurados.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Analizar, desarrollar y programar modelos matemáticos, estadísticos y de simulación.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                }
            ],
            categories: {
                area1: [3, 8, 11, 19, 27, 30, 34, 38, 42, 45, 49, 64, 66, 67, 74, 76],
                area2: [5, 12, 22, 24, 33, 36, 37, 41, 48, 51, 54, 62, 65, 69, 71, 77],
                area3: [4, 9, 14, 18, 20, 25, 28, 32, 35, 43, 52, 55, 58, 61, 70, 79],
                area4: [0, 6, 10, 16, 17, 23, 29, 40, 47, 50, 57, 59, 60, 63, 72, 78],
                area5: [1, 2, 7, 13, 15, 21, 26, 31, 39, 44, 46, 53, 56, 68, 73, 75],
                administracion: [25],
                contador: [14],
                gestion: [4],
                logistica: [35],
                sistemas: [0, 88],
                civil: [6, 86],
                arquitectura: [80, 96],
                electronica: [50, 87],
                mecanica: [59, 91],
                industrial: [92, 103],
                electromecanica: [90, 102],
                aeronautica: [81, 97],
                tics: [93, 104],
                nanotecnologia: [94, 105],
                diseno: [89, 101],
                informatica: [95, 106],
                bioquimica: [84, 100],
                ambiental: [82, 98],
                biomedica: [83, 99],
                quimica: [17, 85],
            },
            categoryNames: {
                area1: 'Arte Y Creatividad',
                area2: 'Ciencias Sociales',
                area3: 'Económica, Administrativa Y Financiera',
                area4: 'Ciencia Y Tecnología',
                area5: 'Ciencias Ecológicas, Biológicas Y De Salud',
                disenografico: 'Diseño Gráfico',
                cine: 'Cine Y Video',
                artes: 'Artes',
                modelaje: 'Modelaje',
                danza: 'Danza',
                fotografia: 'Fotografía',
                comunicacion: 'Comunicación',
                derecho: 'Derecho',
                psicologia: 'Psicología',
                periodismo: 'Periodismo',
                marketing: 'Marketing',
                educacion: 'Educación',
                turismo: 'Turismo',
                administracion: 'Lic. en Administración',
                contador: 'Lic. Contador Público',
                gestion: 'Ing. en Gestión Empresarial',
                logistica: 'Ing. en Logística',
                sistemas: 'Ing. en Sistemas Computacionales',
                civil: 'Ing. Civil',
                arquitectura: 'Arquitectura',
                electronica: 'Ing. Electrónica',
                mecanica: 'Ing. Mecánica',
                industrial: 'Ing. Industrial',
                electromecanica: 'Ing. Electromecánica',
                aeronautica: 'Ing. Aeronáutica',
                tics: 'Ing. en Tecnologías de la Información y Comunicación',
                nanotecnologia: 'Ing. en Nanotecnología',
                diseno: 'Ing. en Diseño Industrial',
                informatica: 'Ing. Informática',
                bioquimica: 'Ing. Bioquímica',
                ambiental: 'Ing. Ambiental',
                biomedica: 'Ing. Biomédica',
                quimica: 'Ing. Química',
                medicina: 'Medicina',
                nutricion: 'Nutrición',
                gastronomia: 'Gastronomía',
                odontologia: 'Odontología',
                enfermeria: 'Enfermería',
                deportes: 'Deportes'
            },
            scores: {
                area1: 0,
                area2: 0,
                area3: 0,
                area4: 0,
                area5: 0,
                administracion: 0,
                contador: 0,
                gestion: 0,
                logistica: 0,
                sistemas: 0,
                civil: 0,
                arquitectura: 0,
                electronica: 0,
                mecanica: 0,
                industrial: 0,
                electromecanica: 0,
                aeronautica: 0,
                tics: 0,
                nanotecnologia: 0,
                diseno: 0,
                informatica: 0,
                bioquimica: 0,
                ambiental: 0,
                biomedica: 0,
                quimica: 0,
            },
        };
    },
    methods: {
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
            }
        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        },
        calculateScores() {
            // Limpiar las puntuaciones actuales
            this.scores = Object.keys(this.scores).reduce((acc, key) => {
                acc[key] = 0;
                return acc;
            }, {});

            // Recorrer las respuestas seleccionadas y sumarlas a la categoría correspondiente
            this.selectedAnswers.forEach((answerScore, index) => {
                // Determina la categoría a la que pertenece la pregunta actual
                for (let category in this.categories) {
                    if (this.categories[category].includes(index)) {
                        this.scores[category] += parseInt(answerScore, 10);
                        break;
                    }
                }
            });
        },
        getTopCategories(scores, topN = 3) {
            // Ordena las categorías por puntuación y devuelve las topN
            return Object.entries(scores)
                .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                .slice(0, topN)
                .map(([category]) => this.categoryNames[category]);
        },
        async submitAnswers() {
            let usuario = localStorage.getItem('usuario');
            this.calculateScores();
            const topCategories = this.getTopCategories(this.scores);

            // Crear una lista de las 3 mejores categorías con su puntaje
            const topScores = Object.entries(this.scores)
                .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                .slice(0, 3);

            // Encontrar el puntaje más alto
            const highestScore = topScores[0][1];

            // Calcular el porcentaje para cada puntaje y formatear el texto
            const scoresPercentages = topScores.map(([, score]) => {
                const percentage = ((score / highestScore) * 100).toFixed(2);
                return `${percentage}%`;
            }).join(', ');

            // Guardar el texto en localStorage
            localStorage.setItem('score-test-intereses-vocacionales', scoresPercentages);

            const data = {
                idResultado: 0,
                idUsuario: usuario,
                resultadoTestMatematico: "",
                resultadoTestEspanol: "",
                resultadoTest1: { content: "" },
                resultadoTest2: { content: "" },
                resultadoTest3: { content: topCategories.toString() },
            };

            try {
                const response = await axios.post('https://www.vocatecnm-api.somee.com/resultado', data);
                // Manejar respuesta exitosa
                localStorage.removeItem('resultado-intereses-vocacionales');
                localStorage.setItem('resultado-intereses-vocacionales', topCategories.join(', '));
                // Redireccionamiento de ruta
                const redirectUrl = this.$route.query.redirect || '/test/personal';
                this.$router.push(redirectUrl);

            } catch (error) {
                console.error('Error:', error);
                // Manejar error
            }

        },
    },
    computed: {
        isLastQuestion() {
            return this.currentQuestionIndex === this.questions.length - 1;
        },
        allQuestionsAnswered() {
            return this.selectedAnswers.length === this.questions.length;
        },
    },
};
</script>

<style scoped>
.content-principal {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    /* Centra verticalmente */
    justify-content: center;
    /* Centra horizontalmente */
}

.quiz-container {
    padding: 20px 20px;
    /* Ajusta el espaciado interno */
    display: flex;
    flex-direction: column;
    /* Alinea los elementos en columna */
    align-items: center;
    /* Centra horizontalmente */
    justify-content: center;
    /* Centra verticalmente */
    margin-top: 100px;
    /* Ajusta el margen superior */
    background-color: #F2F2F2;
    border-radius: 10px;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    width: 900px;
    /* Ajusta el ancho */
    height: 250px;
}

.question-text {
    margin-bottom: 16px;
    font-size: 18px;
    /* Tamaño de fuente de la pregunta */
    font-weight: bold;
    /* Negrita para la pregunta */
    text-align: center;
    /* Centra el texto de la pregunta */
}

.answer-option {
    display: flex;
    /* Coloca el label y el input en una fila */
    align-items: center;
    /* Alinea verticalmente los elementos dentro del contenedor */
    margin-bottom: 12px;
    /* Espaciado entre las opciones de respuesta */
}

.answer-label {
    display: flex;
    /* Coloca el input y el texto en una fila dentro del label */
    align-items: center;
    /* Alinea verticalmente el input y el texto */
    margin-right: 20px;
    /* Espaciado entre cada conjunto de radio button y texto */
    white-space: nowrap;
    /* Evita que el texto se ajuste y haga saltos de línea */
    overflow: hidden;
    /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis;
    /* Añade puntos suspensivos si el texto es demasiado largo */
}

input[type="radio"] {
    margin-right: 8px;
    /* Espaciado entre el radio button y el texto */
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    /* Espaciado superior para los botones de navegación */
    gap: 12px;
    /* Espaciado entre los botones */
}

.navigation-buttons button {
    background-color: #007BFF;
    /* Color de fondo */
    color: #fff;
    /* Color del texto */
    border: none;
    /* Sin borde */
    border-radius: 5px;
    /* Bordes redondeados */
    padding: 10px 20px;
    /* Espaciado interno */
    font-size: 16px;
    /* Tamaño del texto */
    cursor: pointer;
    /* Cursor de puntero */
    transition: background-color 0.3s;
    /* Transición para el cambio de color */
}

.navigation-buttons button:hover {
    background-color: #0056b3;
    /* Color de fondo en hover */
}

.navigation-buttons button:disabled {
    background-color: #d6d6d6;
    /* Color de fondo para botón deshábilitado */
    cursor: not-allowed;
    /* Cursor de no permitido */
}
</style>
