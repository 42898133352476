<template>
    <div class="content-principal">
        <div class="quiz-container">
            <div v-if="currentQuestionIndex < questions.length">
                <h2 class="question-text">{{ questions[currentQuestionIndex].questionText }}</h2>
                <div style="display: flex;">
                    <div v-for="(answer, index) in questions[currentQuestionIndex].answerOptions" :key="index"
                        class="answer-option">
                        <label :for="`answer-${index}`" class="answer-label">
                            <input type="radio" :id="`answer-${index}`" :name="'question-' + currentQuestionIndex"
                                :value="answer.answerScore" v-model="selectedAnswers[currentQuestionIndex]" />
                            {{ answer.answerText }}
                        </label>
                    </div>
                </div>
                <div class="navigation-buttons">
                    <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">Previous</button>
                    <button v-if="!isLastQuestion || !allQuestionsAnswered" @click="nextQuestion">Next</button>
                    <button v-else @click="submitAnswers">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentQuestionIndex: 0,
            selectedAnswers: [],
            correctAnswersSelected: [], // Nueva variable para almacenar las respuestas correctas seleccionadas
            questions: [
                {
                    questionText: "Comprender con facilidad como se conectan algunos elementos, por ejemplo, las relaciones entre los órganos del cuerpo humano, las relaciones que se dan entre elementos químicos, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Decorar, con pincel fino, pequeñas figuras humanas (pintar la boca, ojos, cejas, etc.)",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Ayudar a un especialista en diseño de juegos electrónicos a realizar juegos sencillos que impliquen razonamiento numérico.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Darte cuenta cuándo un conferencista, al estar presentando su tema, lo expone de manera coordinada.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Dar buenas ideas a un grupo de vendedores (que van casa por casa) acerca de cómo convencer a los clientes para comprar el producto ofrecido.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Aprender las partes y funciones de un velero, así como su operación y armado",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Escuchar a alguien durante un largo periodo, sin interrumpirle, sin juzgarlo, y aceptarlo como es.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Distribuir las funciones y responsabilidades a los miembros de un grupo o equipo de trabajo, procurando que todos se sientan lo más conformes con esta distribución.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Tomar un curso sobre cómo realizar los inventarios de un pequeño almacén, mediante una computadora sencilla, y aprender fácilmente.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Apreciar las diferencias entre la música clásica, la popular y la moderna.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Diseñar el logotipo de una empresa.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Armar rompecabezas geométricos (cubo de Ruby, por ejemplo).",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Hacer trazos finos en papel, sin perder la línea y sin ayuda de reglas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Reconocer tus errores cuando discutes con otra persona, aunque ésta no sea de tu total agrado o tenga puntos contrarios a los tuyos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Diseñar una revista de pasatiempo, con problemas sencillos, con base en relaciones numéricas (aritmética-álgebra).",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Explicar fácilmente, al terminar una lectura (sobre cualquier género de novelas), cómo se relacionan los personajes o cómo se desarrolla la trama.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Idear un sistema rápido a un cronista deportivo, para que gráficamente pueda organizar datos deportivos tales como: hits promedios de bateo, bases robadas, home runes, etc., de una temporada.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Ser presidente de un club social y decirle a cada uno de los miembros de la mesa directiva sus aciertos y, sin dudar, sus errores, de forma precisa.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender las partes y funciones de un planeador, así como su operación y armado.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Dar buenas sugerencias a un grupo de publicistas sobre cómo presentar un producto en un comercial de TV o de radio.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender las nomenclaturas químicas o el funcionamiento estructural de todos los órganos del cuerpo humano.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Diseñar figuras geométricas en tres dimensiones, variando las posiciones y perspectivas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender técnicas para realizar pinturas en acuarela y óleo.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender el manejo de la herramienta propia para modelar y esculpir barro, trabajar vidrio, porcelana, latón y piel, de manera artesanal.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta fácilmente si el profesor omitió un dato o punto necesario para la resolución de un problema durante el examen de matemáticas y saber con precisión lo que faltó.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Idear un sistema para pequeñas tiendas de regalos, en el que puedan llevar un inventario de la existencia de sus productos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Hacer un buen resumen de cualquier libro o escrito que hayas leído, con toda la información relevante bien organizada.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Orientarte rápidamente en una gran ciudad.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Operar y dar mantenimiento a un tractor agrícola, incluyendo sus implementos. Cumplir, a la perfección, una tarea asignada por tus padres, un profesor o jefe de trabajo.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Cumplir, a la perfección, una tarea asignada por tus padres, un profesor o jefe de trabajo.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a leer, interpretar y combinar notas musicales.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Decorar bellamente el aparador de una tienda o un puesto en una feria o verbena. Reconocer en una persona que no sea de tu agrado, su fortaleza, sus virtudes y sus aciertos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Reconocer en una persona que no sea de tu agrado, su fortaleza, sus virtudes y sus aciertos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Comentar en un programa televisivo de la universidad, las ventajas y desventajas de las planillas que aspiran a dirigir la sociedad de alumnos, y argumentar a favor de una de ellas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Sacar astillas de la piel con pinzas para las cejas en el primer intento.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a usar métodos científicos para profundizar en el conocimiento de las relaciones que, por ejemplo, hay entre los seres vivos que integran el ecosistema, los cambios químicos que se dan en la naturaleza, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender el punto de vista de un compañero y poder explicar fácilmente todas sus razones para ver las cosas desde esa perspectiva (aunque no te agrade ese punto de vista ni tu compañero).",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender fácilmente artículos de revistas que versan sobre investigación en las ciencias naturales, como química, biología, geofísica, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Detectar, con facilidad, errores en un plan de trabajo que te pidieron revisar (pueden ser errores en fechas, metas que se proponen, pasos, puntos omitidos, etc.).",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Instalar, operar y darle mantenimiento a una computadora casera con sólo estudiar el manual o instructivo de operaciones.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Identificar errores ortográficos, en la organización de los párrafos y errores de redacción de un periódico al momento de estar leyéndolo.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darle buenas sugerencias a un banco pequeño acerca de cómo organizar los departamentos de atención al público con el fin de que los clientes no hagan largas colas de espera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta cuándo un profesor comete errores al estar explicando al grupo la solución (procedimientos) de un problema de, matemáticas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Soldar los transistores de un radio sin derramar soldadura, ni tocar con el soldador partes cercanas al transistor.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Sugerir a alguien que va a construir su casa la forma en que debería ir la distribución de la misma, aprovechando al máximo todos los espacios.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Trabajar durante las vacaciones en el departamento de ún almacén comercial (libros,música, ferretería, etc.) y aumentar en ese periodo las ventas o consumo por los clientes que lo visitan.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a ejecutar, con dominio, uno o varios instrumentos musicales. Recordar, detalladamente, las indicaciones dadas por alguien para llegar a una dirección desconocida.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Recordar detalladamente, las indicaciones dadas por alguien para llegar a una dirección desconocida.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a diferenciar lo bello de la pintura de los grandes clásicos, en relación con otras pinturas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Dar, a una asamblea de padres de familia, un discurso que sea sumamente emotivo y convincente para cambiar ciertas actitudes de ellos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Explicar de modo lógico y claro en una clase cómo evolucionan y se transforman los seres vivos, de acuerdo con las teorías evolucionistas de Darwin, o explicar en qué consistieron los métodos y descubrimientos de algunos grandes químicos como Pasteur.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Fungir como tercero en un conflicto (sin tomar partido por las personas involucradas) y conciliar a las partes en pugna aunque una de ellas te simpatice mucho.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Supervisar y corregir la redacción de un discurso que algún compañero tuyo vaya a dirigir a una asamblea.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender el sistema de clasificación que se utiliza para los archivos de la Nación.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender la clase de matemáticas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Ensartar hilos finos en agujas muy pequeñas, sin que lo intentes más de tres veces.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Interpretar y aplicar el reglamento a un miembro de una sociedad o de un club de amigos que lo haya infringido (aunque esta aplicación implique la expulsión del grupo de alguien muy amigo tuyo).",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender con facilidad los reportajes y artículos de la revista Mecánica popular.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Recordar, durante una segunda visita, el lugar de una colonia intrincada, en donde vive alguien conocido.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a complementar los distintos instrumentos musicales en la ejecución de una pieza.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender técnicas para construir maquetas y objetos de cualquier tipo a escala.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Orientarse con la ayuda de mapas en una gran ciudad.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender fácilmente la explicación de un armero sobre cómo funciona cierto tipo de armamento militar, ya sean fusiles, ametralladoras o artillería pesada.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Prever e identificar las consecuencias para ti y para los demás (de manera detallada y precisa) de las decisiones que tomas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Colocar sin errores, con pinzas pequeñas, los engranes de un reloj.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Explicar una lección de matemáticas a un grupo de compañeros menos aventajados, de manera mas sencilla que el profesor.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender y mejorar el sistema de clasificación y localización de piezas en una refaccionaria.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Explicar de forma clara y lógica a un grupo de personas, con tus propias palabras y con ejemplos ilustrativos, un pasaje o un capítulo de algún libro que hayas leído.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Comprender con facilidad cómo una persona cercana a ti ve desde su interior el mundo. Comprender también los sentimientos que tiene y sus esperanzas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aplicar las leyes y teorías de la química o de la biología cuando resolvemos un problema de esa naturaleza",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Generar argumentos, ante un auditorio, a favor de tu punto de vista o de algún proyecto.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a hacer arreglos musicales.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Resolver problemas de matemáticas, seleccionar las fórmulas y procedimientos necesarios de manera precisa y fácil.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Apretar, con un pequeño desarmador, los tornillos de los anteojos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Planear una estrategia para que un grupo de compañeros tuyos tenga éxito en una campaña política o en cualquier actividad.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender con facilidad a afinar automóviles o a dar mantenimiento y reparación a aparatos caseros como: podadoras de pasto, calentadores de gas, estufas, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Dar ideas al párroco o ministro de tu iglesia acerca de cómo mejorar sus sermones o explicaciones hacia los feligreses.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Obtener fácilmente conclusiones o una síntesis de algún trabajo que haya encargado el profesor de biología o de química sobre cualquier tema.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta de los deseos, preocupaciones o sentimientos de alguien, sólo por ver sus gestos y movimientos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender con mucha precisión las instrucciones y las preguntas de un examen. Diseñarle a una pequeña ferretería un sistema para localizar rápidamente los productos que el cliente pide.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Diseñarle a una pequeña ferretería un sistema para localizar rapidamente los productos que el cliente pide.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Combinar materiales de todo tipo (yeso, madera, pinturas) para hacer objetos de ornato, como floreros, ceniceros, pisapapeles, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a escribir y leer música, y a la vez hacer composiciones musicales. Distinguir errores en las perspectivas de los dibujos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Distinguir errores en las perspectivas de los dibujos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Hacerle ver a un grupo que discute cosas que no han considerado o puntos omitidos que puedan influir en sus opiniones sobre lo discutido.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Construir, en un taller de aficionados, artefactos sencillos como un velero, un planeador o un cuatriciclo, siguiendo los planos de una revista.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Lograr entusiasmar a un grupo de trabajo que se encuentra pesimista en cuanto a las metas de trabajo por lograr.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Enhebrar rápidamente un collar de perlas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta de inmediato de cuál es el error de un compañero que no puede resolver un problema de matemáticas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Participar con un diseño tuyo, con probabilidades de ganar, en un concurso de la escuela cuyo objetivo o meta sea el de proponer un sistema de clasificación para los expedientes de los estudiantes.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta de inmediato cuando un profesor te aplicó un examen con errores de redacción en las instrucciones y en las preguntas.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Predecir la forma en que un conocido tuyo va a actuar, lo que va a decir y a pensar en una situación dada.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Comprender la relación entre estructuras y funciones de los órganos del cuerpo humano; establecer cómo se relacionan todos los sistemas, o hacer esto mismo con temas de química, como por ejemplo, la tabla periódica.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Diseñar la decoración de un hogar o una oficina.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Hacerle a un carpintero, detalladamente (con ángulos, medidas, superficies, etc.), un diseño sobre el tipo de muebles que deseas te haga.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender los métodos (cómo hicieron sus hipótesis, cómo relacionaron, cómo comprobaron, cómo aplicaron sus teorías, etc.) por lo que un químico o algún biólogo famoso logra llegar a sus descubrimientos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta rápidamente cuando alguien está pasando por un mal momento y necesita ayuda, sin que te lo diga.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Escribir un guión teatral o alguna pequeña obra, relacionando y ordenando correctamente los diálogos de los personajes, las escenas, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Mejorar el procedimiento de inscripciones en la escuela, creando uno más rápido, más fácil, práctico y eficiente.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Prestar ayuda al profesor de matemáticas para plantear problemas de repaso referentes a la clase.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Pegar piedras preciosas en un collar, viendo a través de una lupa.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Hacer que todos los miembros de un grupo de trabajo den lo mejor que tienen, sin sentirse presionados o perseguidos.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender (fácilmente) en un curso, la reparación de productos electrónicos, mecánicos, etc., y la teoría que los fundamenta.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender a ser un buen orador mediante un curso rápido y sencillo.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Darte cuenta cuando alguien tuvo errores en la ejecución de una pieza musical.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Dibujar figuras a detalle (sombreadas, en movimiento, etc.).",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Ejemplificar fácilmente cómo se pueden observar en la vida cotidiana las leyes y prin- cipios de la biología o de la química.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Saber identificar el momento oportuno para dar un consejo, empezar una conversación, o hacerle una pregunta a alguien sin que se sienta presionado.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Dirigir un grupo musical que interprete melodías de moda.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Ordenar fácil y rápidamente rompecabezas escritos, cuyas oraciones, párrafos, títulos o subtítulos, estén todos en desorden.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Aprender cómo manejar los datos (nóminas, inventarios, egresos, ingresos, etc.), de un corporativo.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Diseñar guías de examen con problemas y ejercicios de matemáticas para la clase.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Interpretar diversos estilos de música en un grupo de voces.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Atornillar y desatornillar rápidamente y sin errores un reloj de pulso.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Identificar las hábilidades y fortalezas de los miembros de un grupo para asignarles responsabilidades en una tarea determinada.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Entender, fácilmente, la explicación de un experto militar sobre la forma en que se com- binan la electrónica, la electricidad y la mecánica, en la construcción del armamento militar.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Preparar y dar un discurso a un auditorio acerca de la necesidad de cuidar la Tierra de forma tal que logres convencer a la gente de lo grave del deterioro ambiental y la urgencia de tomar las medidas necesarias.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Recordar fácilmente tonadas y letras de las piezas musicales que más te agradan.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Rediseñar con facilidad los lugares de un estacionamiento de coches de forma tal que haya más cupo y fluidez de automóviles.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                },
                {

                    questionText: "Decorar objetos cerámicos, o de piel, de madera, de barro, etcétera.",
                    answerOptions: [
                        { answerText: 'Mucho muy hábil', answerScore: 5 },
                        { answerText: 'Muy hábil', answerScore: 4 },
                        { answerText: 'Medianamente hábil', answerScore: 3 },
                        { answerText: 'Poco hábil', answerScore: 0 },
                        { answerText: 'Nada hábil', answerScore: 0 },
                    ],
                }

            ],
            categories: {
                abscie: [0, 20, 35, 37, 50, 69, 77, 92, 95, 106],
                coovis: [1, 12, 34, 43, 55, 64, 73, 87, 100, 113],
                nume: [2, 14, 24, 42, 54, 65, 72, 88, 99, 111],
                verb: [3, 15, 26, 40, 52, 67, 79, 90, 97, 109],
                pers: [4, 19, 33, 45, 49, 70, 76, 84, 103, 116],
                meca: [5, 18, 28, 39, 57, 62, 75, 85, 102, 115],
                soci: [6, 13, 32, 36, 51, 68, 78, 91, 96, 107],
                dire: [7, 17, 29, 38, 56, 63, 74, 86, 101, 114],
                orga: [8, 16, 25, 41, 53, 66, 80, 89, 98, 110],
                musi: [9, 30, 46, 59, 71, 82, 104, 108, 112, 117],
                artpla: [10, 22, 23, 31, 48, 60, 81, 93, 105, 119],
                espa: [11, 21, 27, 44, 47, 58, 61, 83, 94, 118],
            },
            categoryNames: {
                abscie: 'Abstracta o Científica',
                coovis: 'Coordinación Visomotriz',
                nume: 'Numérica',
                verb: 'Verbal',
                pers: 'Persuasiva',
                meca: 'Mecánica',
                soci: 'Social',
                dire: 'Directiva',
                orga: 'Organización',
                musi: 'Musical',
                artpla: 'Artístico Plástico',
                espa: 'Espacial',
            },
            scores: {
                abscie: 0,
                coovis: 0,
                nume: 0,
                verb: 0,
                pers: 0,
                meca: 0,
                soci: 0,
                dire: 0,
                orga: 0,
                musi: 0,
                artpla: 0,
                espa: 0,
            },
        };
    },
    methods: {
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
            }
        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        },
        calculateScores() {
            // Limpia las puntuaciones actuales
            this.scores = Object.keys(this.scores).reduce((acc, key) => {
                acc[key] = 0;
                return acc;
            }, {});

            // Asume que cada pregunta corresponde a una categoría en el orden de `Object.keys(this.categories)`
            this.selectedAnswers.forEach((answerScore, index) => {
                const category = Object.keys(this.categories)[index];
                if (category) {
                    this.scores[category] += parseInt(answerScore, 10);
                }
            });
        },
        getTopCategories(scores, topN = 5) {
            return Object.entries(scores)
                .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                .slice(0, topN)
                .map(([category]) => this.categoryNames[category]);
        },

        async submitAnswers() {
            let usuario = localStorage.getItem('usuario');
            this.calculateScores();
            const topCategories = this.getTopCategories(this.scores);

            // Crear una lista de las 5 mejores categorías con su puntaje
            const topScores = Object.entries(this.scores)
                .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                .slice(0, 5);

            // Extraer solo los puntajes
            const scoresText = topScores.map(([, score]) => score).join(', ');

            // Guardar el texto en localStorage
            localStorage.setItem('score-test-aptitudes', scoresText);


            const data = {
                idResultado: 0,
                idUsuario: usuario,
                resultadoTestMatematico: "",
                resultadoTestEspanol: "",
                resultadoTest1: { content: topCategories.toString() },
                resultadoTest2: { content: "" },
                resultadoTest3: { content: "" }
            };

            try {
                const response = await axios.post('https://www.vocatecnm-api.somee.com/resultado', data);
                // Manejar respuesta exitosa
                localStorage.removeItem('resultado-aptitudes');
                localStorage.setItem('resultado-aptitudes', topCategories.join(', '));
                // Redireccionamiento de ruta
                const redirectUrl = this.$route.query.redirect || '/test/personal';
                this.$router.push(redirectUrl);

            } catch (error) {
                console.error('Error:', error);
                // Manejar error
            }

        },
    },
    computed: {
        isLastQuestion() {
            return this.currentQuestionIndex === this.questions.length - 1;
        },
        allQuestionsAnswered() {
            return this.selectedAnswers.length === this.questions.length;
        },
    },
};
</script>

<style scoped>
.content-principal {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    /* Centra verticalmente */
    justify-content: center;
    /* Centra horizontalmente */
}

.quiz-container {
    padding: 20px 20px;
    /* Ajusta el espaciado interno */
    display: flex;
    flex-direction: column;
    /* Alinea los elementos en columna */
    align-items: center;
    /* Centra horizontalmente */
    justify-content: center;
    /* Centra verticalmente */
    margin-top: 100px;
    /* Ajusta el margen superior */
    background-color: #F2F2F2;
    border-radius: 10px;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    width: 900px;
    /* Ajusta el ancho */
    height: 250px;
}

.question-text {
    margin-bottom: 16px;
    font-size: 18px;
    /* Tamaño de fuente de la pregunta */
    font-weight: bold;
    /* Negrita para la pregunta */
    text-align: center;
    /* Centra el texto de la pregunta */
}

.answer-option {
    display: flex;
    /* Coloca el label y el input en una fila */
    align-items: center;
    /* Alinea verticalmente los elementos dentro del contenedor */
    margin-bottom: 12px;
    /* Espaciado entre las opciones de respuesta */
}

.answer-label {
    display: flex;
    /* Coloca el input y el texto en una fila dentro del label */
    align-items: center;
    /* Alinea verticalmente el input y el texto */
    margin-right: 20px;
    /* Espaciado entre cada conjunto de radio button y texto */
    white-space: nowrap;
    /* Evita que el texto se ajuste y haga saltos de línea */
    overflow: hidden;
    /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis;
    /* Añade puntos suspensivos si el texto es demasiado largo */
}

input[type="radio"] {
    margin-right: 8px;
    /* Espaciado entre el radio button y el texto */
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    /* Espaciado superior para los botones de navegación */
    gap: 12px;
    /* Espaciado entre los botones */
}

.navigation-buttons button {
    background-color: #007BFF;
    /* Color de fondo */
    color: #fff;
    /* Color del texto */
    border: none;
    /* Sin borde */
    border-radius: 5px;
    /* Bordes redondeados */
    padding: 10px 20px;
    /* Espaciado interno */
    font-size: 16px;
    /* Tamaño del texto */
    cursor: pointer;
    /* Cursor de puntero */
    transition: background-color 0.3s;
    /* Transición para el cambio de color */
}

.navigation-buttons button:hover {
    background-color: #0056b3;
    /* Color de fondo en hover */
}

.navigation-buttons button:disabled {
    background-color: #d6d6d6;
    /* Color de fondo para botón deshábilitado */
    cursor: not-allowed;
    /* Cursor de no permitido */
}
</style>
