<template>
    <div class="content-principal">
        <div class="quiz-container">
            <div v-if="currentQuestionIndex < questions.length">
                <h2 class="question-text">{{ questions[currentQuestionIndex].questionText }}</h2>
                <div style="display: flex;">
                    <div v-for="(answer, index) in questions[currentQuestionIndex].answerOptions" :key="index"
                        class="answer-option">
                        <label :for="`answer-${index}`" class="answer-label">
                            <input type="radio" :id="`answer-${index}`" :name="'question-' + currentQuestionIndex"
                                :value="answer.answerScore" v-model="selectedAnswers[currentQuestionIndex]" />
                            {{ answer.answerText }}
                        </label>
                    </div>
                </div>
                <div class="navigation-buttons">
                    <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">Previous</button>
                    <button v-if="!isLastQuestion || !allQuestionsAnswered" @click="nextQuestion">Next</button>
                    <button v-else @click="submitAnswers">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentQuestionIndex: 0,
            selectedAnswers: [],
            questions: [
                {
                    questionText: "Prefiero seguir en el mismo trabajo durante mucho tiempo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gustan los trabajos con números.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero llevar a cabo los proyectos con cuidado, haciéndolos paso a paso.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero los trabajos en los que sé de antemano qué se espera que haga.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero reglas e instrucciones claras y específicas de trabajo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta prestar atención a los detalles.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Puedo aceptar instrucciones de otros sin discutirlas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta estar seguro de terminar las tareas que se me asignan.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta organizar el lugar de trabajo antes de comenzar con las tareas en si.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta instalar y reparar aparatos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al trabajar la madera manualmente, comopara hacer muebles para el hogar y juegos demadera.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta un ambiente laboral que me permitaresolver problemas mecánicos como repararvehículos y llevar el mantenimiento de los equipos deaire acondicionado.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero trabajar al aire libre en lugar de en una oficina.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta el trabajo en el campo que exige esfuerzo físico.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta armar juguetes a escala.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gustan los paisajes naturales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gustan los deportes.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta fabricar puñales y alhajas de plata.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta leer libros y periódicos científicos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta pensar a fondo las soluciones a los problemas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta llevar a cabo proyectos en función de mis propias ideas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta hacer pruebas de laboratorio.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta trabajar en proyectos que me darán nuevas ideas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta el tipo de trabajo donde puedo hacer cálculos matemáticos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al estudiar y explorar monumentos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto el hacer investigaciones científicas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta estudiar temas específicos de protección ambiental.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al dirigir a los demás en sus actividades.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero las tareas donde puedo planificar y organizar el trabajo de los demás.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta ser el responsable de la planificación de actividades y eventos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al supervisar equipos de trabajo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero el tipo de trabajo que me permite presentar ideas o puntos de vista particulares.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Soy capaz de convencer a los demás de mis puntos de vista.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta organizar actividades a mi modo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Confío mucho en mí mismo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero tener mi propio negocio.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta ayudar a los colegas a solucionar sus problemas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Yo soy quien inicia las conversaciones en las reuniones con colegas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta el tipo de trabajo que está directamente relacionado con el público.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta participar en las actividades escolares grupales.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta trabajar en grupo.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta participar de trabajos voluntarios.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al ocuparme de los demás.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta el tipo de trabajo que implica mejorar la situación social de las personas.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Prefiero el tipo de trabajo que implica educar y formar a los demás.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al tocar un instrumento musical.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al escribir cuentos y artículos.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto las manualidades.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al escribir poesía.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Disfruto al dibujar personas y paisajes.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta participar en actividades de fotografía.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta el teatro.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta participar en las actividades de radio escolares.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                },
                {
                    questionText: "Me gusta el diseño de modas y la decoración.",
                    answerOptions: [
                        { answerText: 'Me Interesa', answerScore: 1 },
                        { answerText: 'No Me Interesa', answerScore: 0 },
                    ],
                }

            ],
            categories: {
                convencional: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                realista: [9, 10, 11, 12, 13, 14, 15, 16, 17],
                investigador: [18, 19, 20, 21, 22, 23, 24, 25, 26],
                emprendedor: [27, 28, 29, 30, 31, 32, 33, 34, 35],
                social: [36, 37, 38, 39, 40, 41, 42, 43, 44],
                artistico: [45, 46, 47, 48, 49, 50, 51, 52, 53],
            },
            categoryNames: {
                convencional: 'Convencional',
                realista: 'Realista',
                investigador: 'Investigador',
                emprendedor: 'Emprendedor',
                social: 'Social',
                artistico: 'Artistico',
            },
            scores: {
                convencional: 0,
                realista: 0,
                investigador: 0,
                emprendedor: 0,
                social: 0,
                artistico: 0,
            },
        };
    },
    methods: {
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
            }
        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        },
        calculateScores() {
            // Limpiar las puntuaciones actuales
            this.scores = Object.keys(this.scores).reduce((acc, key) => {
                acc[key] = 0;
                return acc;
            }, {});

            // Recorrer las respuestas seleccionadas y sumarlas a la categoría correspondiente
            this.selectedAnswers.forEach((answerScore, index) => {
                // Determina la categoría a la que pertenece la pregunta actual
                for (let category in this.categories) {
                    if (this.categories[category].includes(index)) {
                        this.scores[category] += parseInt(answerScore, 10);
                        break;
                    }
                }
            });
        },
        getTopCategories(scores, topN = 3) {
            // Ordena las categorías por puntuación y devuelve las topN
            return Object.entries(scores)
                .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                .slice(0, topN)
                .map(([category]) => this.categoryNames[category]);
        },
        async submitAnswers() {
            let usuario = localStorage.getItem('usuario');
            this.calculateScores();
            const topCategories = this.getTopCategories(this.scores);

            // Crear una lista de las 3 mejores categorías con su puntaje
            const topScores = Object.entries(this.scores)
                .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                .slice(0, 3);

            // Extraer solo los puntajes
            const scoresText = topScores.map(([, score]) => score).join(', ');

            // Guardar el texto en localStorage
            localStorage.setItem('score-test-holland', scoresText);

            const data = {
                idResultado: 0,
                idUsuario: usuario,
                resultadoTestMatematico: "",
                resultadoTestEspanol: "",
                resultadoTest1: { content: "" },
                resultadoTest2: { content: topCategories.toString() },
                resultadoTest3: { content: "" }
            };

            try {
                const response = await axios.post('https://www.vocatecnm-api.somee.com/resultado', data);
                // Manejar respuesta exitosa
                localStorage.removeItem('resultado-holland');
                localStorage.setItem('resultado-holland', topCategories.join(', '));
                // Redireccionamiento de ruta
                const redirectUrl = this.$route.query.redirect || '/test/personal';
                this.$router.push(redirectUrl);

            } catch (error) {
                console.error('Error:', error);
                // Manejar error
            }
        },
    },
    computed: {
        isLastQuestion() {
            return this.currentQuestionIndex === this.questions.length - 1;
        },
        allQuestionsAnswered() {
            return this.selectedAnswers.length === this.questions.length;
        },
    },
};
</script>

<style scoped>
.content-principal {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    /* Centra verticalmente */
    justify-content: center;
    /* Centra horizontalmente */
}

.quiz-container {
    padding: 20px 20px;
    /* Ajusta el espaciado interno */
    display: flex;
    flex-direction: column;
    /* Alinea los elementos en columna */
    align-items: center;
    /* Centra horizontalmente */
    justify-content: center;
    /* Centra verticalmente */
    margin-top: 100px;
    /* Ajusta el margen superior */
    background-color: #F2F2F2;
    border-radius: 10px;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    width: 900px;
    /* Ajusta el ancho */
    height: 250px;
}

.question-text {
    margin-bottom: 16px;
    font-size: 18px;
    /* Tamaño de fuente de la pregunta */
    font-weight: bold;
    /* Negrita para la pregunta */
    text-align: center;
    /* Centra el texto de la pregunta */
}

.answer-option {
    display: flex;
    /* Coloca el label y el input en una fila */
    align-items: center;
    /* Alinea verticalmente los elementos dentro del contenedor */
    margin-bottom: 12px;
    /* Espaciado entre las opciones de respuesta */
}

.answer-label {
    display: flex;
    /* Coloca el input y el texto en una fila dentro del label */
    align-items: center;
    /* Alinea verticalmente el input y el texto */
    margin-right: 20px;
    /* Espaciado entre cada conjunto de radio button y texto */
    white-space: nowrap;
    /* Evita que el texto se ajuste y haga saltos de línea */
    overflow: hidden;
    /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis;
    /* Añade puntos suspensivos si el texto es demasiado largo */
}

input[type="radio"] {
    margin-right: 8px;
    /* Espaciado entre el radio button y el texto */
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    /* Espaciado superior para los botones de navegación */
    gap: 12px;
    /* Espaciado entre los botones */
}

.navigation-buttons button {
    background-color: #007BFF;
    /* Color de fondo */
    color: #fff;
    /* Color del texto */
    border: none;
    /* Sin borde */
    border-radius: 5px;
    /* Bordes redondeados */
    padding: 10px 20px;
    /* Espaciado interno */
    font-size: 16px;
    /* Tamaño del texto */
    cursor: pointer;
    /* Cursor de puntero */
    transition: background-color 0.3s;
    /* Transición para el cambio de color */
}

.navigation-buttons button:hover {
    background-color: #0056b3;
    /* Color de fondo en hover */
}

.navigation-buttons button:disabled {
    background-color: #d6d6d6;
    /* Color de fondo para botón deshábilitado */
    cursor: not-allowed;
    /* Cursor de no permitido */
}
</style>
