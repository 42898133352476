<template>

    <body style="user-select: none;">

        <header class="header" data-header>
            <div class="container">
                <h1 style="color:white;">VocaTecnm</h1>
                <nav class="navbar" data-navbar>

                    <div class="navbar-top">
                        <a href="#" class="logo">

                        </a>

                        <button class="nav-close-btn" aria-label="close menu" data-nav-toggler>
                            <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
                        </button>
                    </div>

                    <ul class="navbar-list">
                        <li>
                            <router-link to="/acceso/signin" class="navbar-link">Iniciar Sesión</router-link>
                        </li>
                        <li>
                            <router-link to="/acceso/signup" class="btn btn-primary">Registrarse</router-link>
                        </li>
                    </ul>
                </nav>


                <button class="nav-open-btn" aria-label="open menu" data-nav-toggler>
                    <ion-icon name="menu-outline" aria-hidden="true"></ion-icon>
                </button>

                <div class="overlay" data-nav-toggler data-overlay></div>

            </div>
        </header>


        <main>
            <article>

                <section class="section hero has-bg-image" aria-label="home"
                    style="background:linear-gradient(270deg, #0F4392 0%, #001D3D 100%)">
                    <div class="container">

                        <div class="hero-content">

                            <h1 class="h1 hero-title">¿No Sabes Que Carrera Estudiar?</h1>

                            <p class="hero-text">
                                El test vocacional guía a los estudiantes en la elección de carrera durante la
                                orientación vocacional.
                            </p>

                            <div class="btn-wrapper">
                                <router-link to="/acceso/signin"><a href="#" class="btn btn-primary">Realizar
                                        Ahora</a></router-link>

                            </div>

                        </div>

                        <div class="hero-slider" data-slider>
                            <div class="slider-inner">
                                <ul class="slider-container" data-slider-container>
                                    <figure class="img-holder" style="--width: 575; --height: 550;">
                                        <img :src="img1" width="575" height="550" alt="" class="img-cover">
                                    </figure>
                                </ul>
                            </div>
                        </div>

                    </div>
                </section>




                <section class="section service" aria-labelledby="service-label">
                    <div class="container">

                        <p class="section-subtitle" id="service-label">¿Por Qué Hacer Un Test Vocacional?</p>

                        <h2 class="h2 section-title">
                            Estos Son Algunos Beneficios
                        </h2>

                        <ul class="grid-list">

                            <li>
                                <div class="service-card">

                                    <div class="card-icon">
                                        <span id="iconsbeneficios" class="material-symbols-rounded">psychology</span>
                                    </div>

                                    <h3 class="h4 card-title">Autoconocimiento Mejorado</h3>

                                    <p class="card-text">
                                        Los tests de orientación vocacional permiten a los individuos explorar y
                                        comprender mejor sus
                                        intereses, habilidades y aptitudes.
                                    </p>

                                </div>
                            </li>

                            <li>
                                <div class="service-card">

                                    <div class="card-icon">
                                        <span id="iconsbeneficios" class="material-symbols-rounded">auto_stories</span>
                                    </div>

                                    <h3 class="h4 card-title">Exploración de Carreras Alineadas</h3>

                                    <p class="card-text">
                                        Facilitan la exploración de opciones profesionales al proporcionar una
                                        evaluación objetiva de los
                                        intereses y aptitudes de una persona.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="service-card">

                                    <div class="card-icon">
                                        <span id="iconsbeneficios" class="material-symbols-rounded">balance</span>
                                    </div>

                                    <h3 class="h4 card-title">Toma de Decisiones Informada</h3>

                                    <p class="card-text">
                                        Los resultados de los tests ofrecen datos concretos que respaldan la toma de
                                        decisiones informada.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="service-card">

                                    <div class="card-icon">
                                        <span id="iconsbeneficios" class="material-symbols-rounded">work</span>
                                    </div>

                                    <h3 class="h4 card-title">Alineación con Satisfacción Laboral</h3>

                                    <p class="card-text">
                                        Favorecen la satisfacción laboral al orientar a las personas hacia entornos
                                        laborales que coinciden con sus preferencias y estilos de trabajo.
                                    </p>
                                </div>
                            </li>

                        </ul>

                    </div>
                </section>

                <section class="about" aria-labelledby="about-label">
                    <div class="container">

                        <figure class="about-banner">
                            <img :src="img2" width="700" height="500" loading="lazy" alt="about banner" class="w-100">
                        </figure>

                        <div class="about-content">

                            <p class="section-subtitle" id="feautre-label">La aplicación de tests abarca diversos
                                aspectos</p>

                            <h2 class="h2 section-title">
                                Explora tus Intereses y Aptitudes para una Carrera Profesional Informada
                            </h2>

                            <ul>

                                <li class="about-item">
                                    <div class="accordion-card expanded" data-accordion>

                                        <h3 class="card-title">
                                            <button class="accordion-btn" data-accordion-btn>
                                                <span id="iconsbeneficios2"
                                                    class="material-symbols-rounded down">keyboard_arrow_down</span>
                                                <spna class="span h5">Intereses profesionales</spna>
                                            </button>
                                        </h3>

                                        <p class="accordion-content">
                                            Los tests vocacionales se utilizan para medir las actividades y ocupaciones
                                            que resultan más
                                            atractivas
                                            para los individuos, proporcionando información valiosa sobre sus
                                            preferencias en el ámbito
                                            profesional.
                                        </p>

                                    </div>
                                </li>

                                <li class="about-item">
                                    <div class="accordion-card" data-accordion>

                                        <h3 class="card-title">
                                            <button class="accordion-btn" data-accordion-btn>
                                                <span id="iconsbeneficios2"
                                                    class="material-symbols-rounded down">keyboard_arrow_down</span>

                                                <spna class="span h5">Habilidades y aptitudes</spna>
                                            </button>
                                        </h3>

                                        <p class="accordion-content">
                                            La utilización de tests mentales permite evaluar las habilidades y aptitudes
                                            de los individuos,
                                            midiendo su capacidad para llevar a cabo diversas tareas y actividades,
                                            ofreciendo así una visión
                                            integral de sus competencias.
                                        </p>

                                    </div>
                                </li>

                                <li class="about-item">
                                    <div class="accordion-card" data-accordion>

                                        <h3 class="card-title">
                                            <button class="accordion-btn" data-accordion-btn>
                                                <span id="iconsbeneficios2"
                                                    class="material-symbols-rounded down">keyboard_arrow_down</span>

                                                <spna class="span h5">Personalidad y el estilo de trabajo</spna>
                                            </button>
                                        </h3>

                                        <p class="accordion-content">
                                            Los tests mentales son herramientas efectivas para evaluar la personalidad y
                                            el estilo de trabajo de
                                            los individuos. Evalúan la capacidad para trabajar en equipo, liderar
                                            proyectos, resolver conflictos
                                            y adaptarse a distintos entornos laborales, proporcionando una comprensión
                                            detallada de su idoneidad
                                            para diferentes roles profesionales.
                                        </p>

                                    </div>
                                </li>

                                <li class="about-item">
                                    <div class="accordion-card" data-accordion>

                                        <h3 class="card-title">
                                            <button class="accordion-btn" data-accordion-btn>
                                                <span id="iconsbeneficios2"
                                                    class="material-symbols-rounded down">keyboard_arrow_down</span>

                                                <spna class="span h5">Necesidades de formación y desarrollo</spna>
                                            </button>
                                        </h3>

                                        <p class="accordion-content">
                                            En el ámbito de la formación y desarrollo, los tests mentales son utilizados
                                            para evaluar las
                                            habilidades y conocimientos actuales de los individuos. Estos tests permiten
                                            identificar las áreas
                                            en las que necesitan mejorar y determinar las habilidades y conocimientos
                                            necesarios para avanzar en
                                            sus carreras.
                                        </p>

                                    </div>
                                </li>
                            </ul>

                        </div>

                    </div>
                </section>

                <section class="section feature" aria-labelledby="feature-label">
                    <div class="container">

                        <figure class="feature-banner">
                            <img :src="img3" width="800" height="531" loading="lazy" alt="feature banner" class="w-100">
                        </figure>

                        <div class="feature-content">

                            <h2 class="h2 section-title">
                                Descubre tu futuro con nuestra plataforma para el Tecnológico de Tijuana
                            </h2>

                            <p class="section-text">
                                Ofrecemos una experiencia personalizada que evalúa tus intereses, habilidades y
                                aptitudes,
                                proporcionándote valiosas orientaciones para tomar decisiones informadas sobre las
                                diversas carreras
                                ofrecidas en el Instituto Tecnológico de Tijuana
                            </p>

                            <ul class="feature-list">

                                <li>
                                    <div class="feature-card">

                                        <span id="iconsbeneficios3"
                                            class="material-symbols-rounded down">trip_origin</span>


                                        <span class="span">
                                            Ingeniería en Sistemas Computacionales
                                        </span>

                                    </div>
                                </li>

                                <li>
                                    <div class="feature-card">

                                        <span id="iconsbeneficios3"
                                            class="material-symbols-rounded down">trip_origin</span>


                                        <span class="span">
                                            Ingeniería en Informática
                                        </span>

                                    </div>
                                </li>

                                <li>
                                    <div class="feature-card">
                                        <span id="iconsbeneficios3"
                                            class="material-symbols-rounded down">trip_origin</span>


                                        <span class="span">
                                            Ingeniería en TICS
                                        </span>

                                    </div>
                                </li>

                                <li>
                                    <div class="feature-card">

                                        <span id="iconsbeneficios3"
                                            class="material-symbols-rounded down">trip_origin</span>


                                        <span class="span">
                                            Ingeniería Electromecánica
                                        </span>

                                    </div>
                                </li>

                            </ul>

                        </div>

                    </div>
                </section>

            </article>
        </main>

        <footer class="footer">
            <div class="container">

                <div class="footer-content">
                    <br>
                    <p>
                        &copy; VocaTecnm
                    </p>
                </div>

            </div>
        </footer>
    </body>
</template>

<script>
import img1 from '@/assets/img/hero-slide-1.png'
import img2 from '@/assets/img/about-banner.png'
import img3 from '@/assets/img/feature-banner.png'

export default {
    mounted() {
        const accordions = document.querySelectorAll("[data-accordion]");
        let lastActiveAccordion = accordions[0];

        const initAccordion = function (currentAccordion) {
            const accordionBtn = currentAccordion.querySelector("[data-accordion-btn]");

            const expandAccordion = function () {
                if (lastActiveAccordion && lastActiveAccordion !== currentAccordion) {
                    lastActiveAccordion.classList.remove("expanded");
                }
                currentAccordion.classList.toggle("expanded");
                lastActiveAccordion = currentAccordion;
            }

            accordionBtn.addEventListener("click", expandAccordion);
        }

        for (let i = 0, len = accordions.length; i < len; i++) {
            initAccordion(accordions[i]);
        }
    },
    data() {
        return {
            img1,
            img2,
            img3
        }
    }
}

</script>

<style>
/*-----------------------------------*\
  #main.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */

    --violet-blue-crayola: #0D3166;
    --dark-cornflower-blue_a7: #0D3166;
    --white: hsla(0, 0%, 100%, 1);
    --white_a3: hsla(0, 0%, 100%, 0.03);
    --white_a8: hsla(0, 0%, 100%, 0.08);
    --white_a12: hsla(0, 0%, 100%, 0.12);
    --white_a70: hsla(0, 0%, 100%, 0.7);
    --cultured: hsla(220, 20%, 97%, 1);
    --lavender-web: hsla(233, 52%, 94%, 1);
    --cadet-blue-crayola: hsla(220, 12%, 70%, 1);
    --cadet-blue-crayola_a20: hsla(222, 23%, 71%, 0.2);
    --charcoal: #0D3166;
    --raisin-black: hsla(216, 14%, 14%, 1);
    --light-gray: hsla(0, 0%, 79%, 1);
    --blue-crayola: hsla(219, 72%, 56%, 1);
    --black-coral: hsla(220, 12%, 43%, 1);

    /**
     * typography
     */

    --ff-manrope: sans-serif;

    --fs-1: calc(2.7rem + 1.38vw);
    --fs-2: calc(2.6rem + .66vw);
    --fs-3: 2.2rem;
    --fs-4: 1.9rem;
    --fs-5: 1.8rem;
    --fs-6: 1.7rem;
    --fs-7: 1.5rem;
    --fs-8: 1.4rem;

    --fw-700: 700;

    /** 
     * spacing
     */

    --section-padding: 90px;

    /**
     * box shadow
     */

    --shadow-1: 0 0 20px hsla(216, 14%, 14%, 0.05);
    --shadow-2: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    --shadow-3: 0 0 1.25rem hsla(216, 14%, 14%, 0.04);

    /**
     * border radius
     */

    --radius-circle: 50%;
    --radius-pill: 100px;
    --radius-10: 10px;
    --radius-8: 8px;
    --radius-6: 6px;

    /**
     * transition
     */

    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --transition-3: 0.3s ease-in-out;

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a,
img,
span,
input,
button,
ion-icon {
    display: block;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    height: auto;
}

input,
button {
    background: none;
    border: none;
    font: inherit;
}

input {
    width: 100%;
}

button {
    cursor: pointer;
}

ion-icon {
    pointer-events: none;
}

address {
    font-style: normal;
}

html {
    font-family: var(--ff-manrope);
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: var(--white);
    color: var(--black-coral);
    font-size: 1.6rem;
    line-height: 1.7;
}

body.nav-active {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0, 0%, 70%);
}


#iconsbeneficios {
    margin-top: 0%;
    font-size: 3.5rem;
    font-weight: 400;
}

#iconsbeneficios2 {
    color: #0D3166;
    font-weight: 600;
}

#iconsbeneficios3 {
    color: #0D3166;
    font-size: 1.75rem;
    font-weight: 800;
}

/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
    padding-inline: 16px;
}

.social-list {
    display: flex;
    align-items: center;
    gap: 12px;
}

.social-link {
    font-size: 2rem;
    transition: var(--transition-1);
}

.social-link:is(:hover, :focus-visible) {
    transform: translateY(-3px);
}

.section {
    padding-block: var(--section-padding);
}

.has-bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.h1,
.h2,
.h3,
.h4,
.h5 {
    color: var(--charcoal);
    font-weight: var(--fw-700);
    line-height: 1.3;
}

.h1 {
    font-size: var(--fs-1);
}

.h2 {
    font-size: var(--fs-2);
}

.h3 {
    font-size: var(--fs-3);
}

.h4 {
    font-size: var(--fs-4);
}

.h5 {
    font-size: var(--fs-6);
}

.btn {
    color: var(--white);
    font-size: var(--fs-6);
    font-weight: var(--fw-700);
    border: 2px solid var(--white);
    max-width: max-content;
    padding: 12px 28px;
    border-radius: var(--radius-pill);
    transition: var(--transition-1);
    will-change: transform;
}

.btn:is(:hover, :focus-visible) {
    transform: translateY(-4px);
}

.btn-primary,
.btn-outline:is(:hover, :focus-visible) {
    background-color: var(--white);
    color: var(--charcoal);
}

.img-holder {
    aspect-ratio: var(--width) / var(--height);
    overflow: hidden;
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-subtitle {
    font-size: 1.74rem;
    text-transform: uppercase;
    color: var(--violet-blue-crayola);
    font-weight: var(--fw-700);
    margin-block-end: 16px;
}

.grid-list {
    display: grid;
    gap: 25px;
}

.w-100 {
    width: 100%;
}





/*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/

.header .btn {
    display: none;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-block: 20px;
    box-shadow: var(--shadow-1);
    z-index: 4;
}

.header.active {
    background-color: var(--white);
    position: fixed;
    animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.header.active .logo-light,
.header .logo-dark {
    display: none;
}

.header .logo-light,
.header.active .logo-dark {
    display: block;
}

.nav-open-btn {
    font-size: 3.5rem;
    color: var(--white);
}

.header.active .nav-open-btn {
    color: var(--charcoal);
}

.navbar {
    position: fixed;
    top: 0;
    left: -300px;
    background-color: var(--raisin-black);
    color: var(--white);
    max-width: 300px;
    width: 100%;
    height: 100vh;
    padding: 30px;
    padding-block-end: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 2;
    visibility: hidden;
    transition: var(--transition-3);
}

.navbar.active {
    visibility: visible;
    transform: translateX(300px);
}

.navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-close-btn {
    background-color: var(--white_a8);
    color: var(--white);
    font-size: 2rem;
    padding: 6px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
}

.nav-close-btn ion-icon {
    --ionicon-stroke-width: 50px;
}

.nav-close-btn:is(:hover, :focus-visible) {
    background-color: var(--white_a12);
}

.navbar-list {
    margin-block-end: auto;
}

.navbar-link {
    font-weight: var(--fw-700);
    padding-block: 6px;
}

.contact-link {
    transition: var(--transition-1);
}

.contact-link:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--raisin-black);
    pointer-events: none;
    opacity: 0;
    transition: var(--transition-1);
}

.overlay.active {
    pointer-events: all;
    opacity: 0.8;
}

/*-----------------------------------*\
    #HERO
  \*-----------------------------------*/

.hero {
    padding-block-start: calc(var(--section-padding) + 70px);
    text-align: center;
}

.hero .container {
    display: grid;
    gap: 70px;
}

.hero-title {
    color: var(--white);
}

.hero-text {
    font-size: var(--fs-5);
    color: var(--white);
    margin-block: 24px 36px;
}

.btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.hero-slider,
.hero-card {
    position: relative;

}

.hero-card .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    color: var(--charcoal);
    font-size: 2rem;
    padding: 25px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
    animation: pulse 2s ease infinite;
}

.hero-card .play-btn:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--white_a70);
    }

    75% {
        box-shadow: 0 0 0 20px transparent;
    }
}

.hero .slider-inner {
    border-radius: var(--radius-10);
    overflow: hidden;
}

.hero .slider-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    transition: var(--transition-2);
}

.hero .slider-item {
    min-width: 100%;
    width: 100%;
    border-radius: var(--radius-10);
    overflow: hidden;
}

.hero .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--white_a70);
    color: var(--charcoal);
    font-size: 2rem;
    padding: 12px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
}

.hero .slider-btn:is(:hover, :focus-visible) {
    background-color: var(--white);
}

.hero .slider-btn.prev {
    left: 20px;
}

.hero .slider-btn.next {
    right: 20px;
}


/*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/

.service {
    text-align: center;
}

.section .service {
    margin-top: 0px;
}

.service .section-title {
    margin-block-end: 50px;
}

.service-card {
    padding: 40px;
    box-shadow: var(--shadow-2);
    border-radius: var(--radius-6);
    height: 350px;
}

.service-card .card-icon {
    width: 60px;
    height: 60px;
    background-color: var(--violet-blue-crayola);
    display: grid;
    place-items: center;
    color: var(--white);
    font-size: 2.5rem;
    border-radius: var(--radius-circle);
    margin-inline: auto;
}

.service-card .card-icon ion-icon {
    --ionicon-stroke-width: 50px;
}

.service-card .card-title {
    margin-block: 16px 10px;
}

.service-card .btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-block-start: 10px;
    color: var(--violet-blue-crayola);
    font-weight: var(--fw-700);
    transition: var(--transition-1);
}

.service-card .btn-text:is(:hover, :focus-visible) {
    opacity: 0.9;
}





/*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/

.about .container {
    display: grid;
    gap: 50px;
}

.about .section-title {
    margin-block-end: 35px;
}

.accordion-card .card-title {
    padding-block-end: 20px;
}

.accordion-btn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.accordion-btn ion-icon {
    font-size: 1.5rem;
    color: var(--blue-crayola);
    transition: var(--transition-1);
}

.accordion-card.expanded .accordion-btn ion-icon {
    transform: rotate(0.5turn);
}

.accordion-btn .span {
    transition: var(--transition-1);
}

.accordion-btn:is(:hover, :focus-visible) .span,
.accordion-card.expanded .accordion-btn .span {
    color: var(--violet-blue-crayola);
}

.accordion-content {
    padding-inline-start: 24px;
    max-height: 0;
    overflow: hidden;
}

.accordion-card.expanded .accordion-content {
    max-height: max-content;
    padding-block-end: 20px;
}





/*-----------------------------------*\
    #FEATURES
  \*-----------------------------------*/

.feature .container {
    display: grid;
    gap: 50px;
}

.feature .section-text {
    margin-block: 25px 30px;
}

.section-text {
    text-align: justify;
}

.feature-list {
    display: grid;
    gap: 15px;
}

.feature-card {
    display: flex;
    align-items: center;
    gap: 10px;
}

.feature-card .card-icon {
    background-color: var(--lavender-web);
    font-size: 1.4rem;
    padding: 4px;
    border-radius: var(--radius-circle);
}

.feature-card .card-icon ion-icon {
    --ionicon-stroke-width: 40px;
}






/*-----------------------------------*\
    #STATS
  \*-----------------------------------*/

.stats {
    background-image: linear-gradient(to bottom, var(--white) 50%, var(--cultured) 50%);
}

.stats-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 36px 24px;
    padding: 45px 30px;
    border-radius: var(--radius-8);
}

.stats-card .card-text>* {
    color: var(--white);
}





/*-----------------------------------*\
    #PROJECT
  \*-----------------------------------*/

.project {
    background-color: var(--cultured);
}

.project :is(.section-subtitle, .section-title) {
    text-align: center;
}

.project .section-title {
    margin-block-end: 50px;
}

.project-card {
    background-color: var(--white);
    border-radius: var(--radius-8);
    overflow: hidden;
    box-shadow: var(--shadow-2);
    height: 100%;
}

.about-item p {
    text-align: justify;
}

.project-card .card-content {
    padding: 30px;
}

.project-card .card-title {
    transition: var(--transition-1);
}

.project-card .card-title:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
}

.project-card .card-text {
    margin-block: 16px 20px;
}

.project-card .card-meta-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.project-card .card-meta-item {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--cadet-blue-crayola);
    font-size: var(--fs-8);
}





/*-----------------------------------*\
    #CTA
  \*-----------------------------------*/

.cta {
    background-color: var(--charcoal);
}

.cta .container {
    padding-block: 100px 60px;
    border-block-end: 1px solid var(--cadet-blue-crayola_a20);
}

.cta .section-title {
    color: var(--white);
    margin-block-end: 30px;
}

.cta .btn {
    background-color: var(--violet-blue-crayola);
    color: var(--white);
    border: none;
}


.service-card p {
    text-align: justify;
}


/*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/

.footer {
    background: linear-gradient(270deg, #0F4392 0%, #001D3D 100%);
    height: 80px;
    color: var(--light-gray);
}

.footer-content {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;

}

.footer-content p {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 2.3rem;
}

.input-wrapper {
    position: relative;
    margin-block-start: 25px;
}

.input-field {
    background-color: var(--white_a3);
    color: var(--light-gray);
    font-size: var(--fs-7);
    padding: 12px 16px;
    border: 1px solid var(--dark-cornflower-blue_a7);
    border-radius: var(--radius-6);
    box-shadow: var(--shadow-3);
    outline: none;
}

.input-field::placeholder {
    color: inherit;
}

.submit-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 var(--radius-6) var(--radius-6) 0;
    background-color: var(--violet-blue-crayola);
    color: var(--white);
    padding-inline: 24px;
    font-weight: var(--fw-700);
}





/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for large than 575px screen
   */

@media (min-width: 575px) {

    /**
     * REUSED STYLE
     */

    .container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }



    /**
     * PROJECTS
     */

    .project-card .card-content {
        padding: 40px;
    }

}





/**
   * responsive for large than 768px screen
   */

@media (min-width: 768px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * spacing
       */

        --section-padding: 100px;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 720px;
    }

    .grid-list {
        grid-template-columns: 1fr 1fr;
    }



    /**
     * HEADER
     */

    .header .btn {
        display: block;
        margin-inline-start: auto;
        padding: 8px 20px;
    }

    .header.active .btn {
        background-color: var(--violet-blue-crayola);
        border-color: var(--violet-blue-crayola);
        color: var(--white);
    }



    /**
     * HERO
     */

    .hero-content {
        max-width: 85%;
        margin-inline: auto;
    }

    .hero-text {
        --fs-5: 2rem;
    }



    /**
     * SERVICE
     */

    .service .section-title {
        max-width: 30ch;
        margin-inline: auto;
    }



    /**
     * ABOUT
     */

    .about .container {
        grid-template-columns: 1fr 0.7fr;
        align-items: center;
    }



    /**
     * FEATURE
     */

    .feature .container {
        grid-template-columns: 0.7fr 1fr;
        align-items: center;
    }

    .feature-banner {
        order: 1;
    }

}





/**
   * responsive for large than 992px screen
   */

@media (min-width: 992px) {

    /**
     * REUSED STYLE
     */

    .container {
        max-width: 960px;
    }



    /**
     * HEADER
     */

    .nav-open-btn,
    .navbar>*:not(.navbar-list),
    .overlay {
        display: none;
    }

    .navbar,
    .navbar.active {
        all: unset;
        display: block;
        margin-inline: auto 24px;
    }

    .navbar-list {
        display: flex;
        gap: 30px;
    }

    .navbar-link {
        color: var(--white);
        transition: var(--transition-1);
    }

    .navbar-link:is(:hover, :focus-visible) {
        opacity: 0.7;
    }

    .header.active .navbar-link {
        color: var(--charcoal);
    }

    .header.active .navbar-link:is(:hover, :focus-visible) {
        opacity: 1;
        color: var(--violet-blue-crayola);
    }

    .header .btn {
        margin-inline-start: 0;
    }



    /**
     * HERO
     */

    .hero {
        padding-block-start: calc(var(--section-padding) + 50px);
        text-align: left;
    }

    .hero .container {
        grid-template-columns: 1fr 0.8fr;
        align-items: center;
    }

    .hero-content {
        max-width: unset;
        margin-inline: 0;
    }

    .btn-wrapper {
        justify-content: flex-start;
    }



    /**
     * STATS
     */

    .stats-card {
        grid-template-columns: repeat(4, 1fr);
    }



    /**
     * PROJECT
     */

    .project .section-title {
        max-width: 32ch;
        margin-inline: auto;
    }



    /**
     * CTA
     */

    .cta .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cta .btn {
        min-width: max-content;
    }

    .cta .section-title {
        max-width: 30ch;
        margin-block-end: 0;
    }



    /**
     * FOOTER
     */

    .footer .grid-list {
        grid-template-columns: repeat(4, 1fr);
    }

}





/**
   * responsive for large than 1200px screen
   */

@media (min-width: 1200px) {

    /**
     * REUSED STYLE
     */

    .container {
        max-width: 1140px;
    }



    /**
     * HERO
     */

    .hero-text {
        padding-inline-end: 100px;
    }



    /**
     * SERVICE
     */

    .service .grid-list {
        grid-template-columns: repeat(4, 1fr);
    }



    /**
     * STATS
     */

    .stats .container {
        max-width: 70%;
    }

    .stats-card {
        padding: 60px;
    }



    /**
     * PROJECT
     */

    .project .grid-list {
        grid-template-columns: repeat(3, 1fr);
    }



}



@media (min-width: 1400px) {


    .container {
        max-width: 1320px;
    }

    .feature-list {
        grid-template-columns: 1fr 1fr;
    }

}
</style>