<template>
    <div class="content-principal">
        <div class="quiz-container">
            <div v-if="currentQuestionIndex < questions.length">
                <h2 class="question-text">{{ questions[currentQuestionIndex].questionText }}</h2>
                <div style="display: flex;">
                    <div v-for="(answer, index) in questions[currentQuestionIndex].answerOptions" :key="index"
                        class="answer-option">
                        <label :for="`answer-${index}`" class="answer-label">
                            <input type="radio" :id="`answer-${index}`" :name="'question-' + currentQuestionIndex"
                                :value="answer.answerText" v-model="selectedAnswers[currentQuestionIndex]" />
                            {{ answer.answerText }}
                        </label>
                    </div>
                </div>
                <div class="navigation-buttons">
                    <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">Previous</button>
                    <button v-if="!isLastQuestion || !allQuestionsAnswered" @click="nextQuestion">Next</button>
                    <button v-else @click="submitAnswers">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentQuestionIndex: 0,
            maxScore: 50, // Puntuación máxima posible
            correctScore: 0, //Preguntas acertadas
            calificacionPorcentaje: 0, //Calificacion final
            selectedAnswers: [],
            correctAnswersSelected: [], // Nueva variable para almacenar las respuestas correctas seleccionadas
            questions: [
				{
					questionText: '.... es a ancho lo que delgado es a ....',
					answerOptions: [
						{ answerText: 'A) Tienda, grueso', isCorrect: false },
						{ answerText: 'B) Angosto, peso', isCorrect: true },
						{ answerText: 'C) Nada, hombre', isCorrect: false },
						{ answerText: 'D) Calle, presente', isCorrect: false },
					],
				},
				{
					questionText: '.... es a perro lo que cebú es a ....',
					answerOptions: [
						{ answerText: 'A) Sabueso, toro', isCorrect: true },
						{ answerText: 'B) Cola, caballo', isCorrect: false },
						{ answerText: 'C) Ladrar, noble', isCorrect: false },
						{ answerText: 'D) Gato, muebles', isCorrect: false },
					],
				},
				{
					questionText: '.... es a colgar lo que guillotina es a ....',
					answerOptions: [
						{ answerText: 'A) Cuadro, revolucion', isCorrect: false },
						{ answerText: 'B) Horca, decapitar', isCorrect: true },
						{ answerText: 'C) Criminal, capitular', isCorrect: false },
						{ answerText: 'D) Castigar, ciudadano', isCorrect: false },
					],
				},
				{
					questionText: '.... es a Pepe lo que Francisca es a ....',
					answerOptions: [
						{ answerText: 'A) José, María', isCorrect: false },
						{ answerText: 'B) Francisco, Pancha', isCorrect: true },
						{ answerText: 'C) Ricardo, Juana', isCorrect: false },
						{ answerText: 'D) Fernando, Margarita', isCorrect: false },
					],
				},
				{
					questionText: '.... es a masculino lo que mujer es a ....',
					answerOptions: [
						{ answerText: 'A) Disfraz, intuitivo', isCorrect: false },
						{ answerText: 'B) Malicioso, señora', isCorrect: false },
						{ answerText: 'C) Viril, femenino', isCorrect: false },
						{ answerText: 'D) Hombre, niña', isCorrect: true },
					],
				},
				{
					questionText: '.... es a niñez lo que adolescencia es a ....',
					answerOptions: [
						{ answerText: 'A) Infantería, adulterio', isCorrect: false },
						{ answerText: 'B) Infancia. madurez', isCorrect: true },
						{ answerText: 'C) Destete, prebenda', isCorrect: false },
						{ answerText: 'D) Salud, inteligencia', isCorrect: false },
					],
				},
				{
					questionText: '.... es a corcho lo que caja es a ....',
					answerOptions: [
						{ answerText: 'A) Botella, dinero', isCorrect: true },
						{ answerText: 'B) Pescar, tapa', isCorrect: false },
						{ answerText: 'C) Quebradizo, sombrero', isCorrect: false },
						{ answerText: 'D) Ligero, canasto', isCorrect: false },
					],
				},
				{
					questionText: '.... es a animal lo que corteza es a ....',
					answerOptions: [
						{ answerText: 'A) Cáscara, duro', isCorrect: true },
						{ answerText: 'B) Pétalo, árbol', isCorrect: false },
						{ answerText: 'C) Piel, nuez', isCorrect: false },
						{ answerText: 'D) Hombre, frijol', isCorrect: false },
					],
				},
				{
					questionText: '.... es a verso lo que escultor es a ....',
					answerOptions: [
						{ answerText: 'A) Poeta, crimen', isCorrect: false },
						{ answerText: 'B) Reverso, cincel', isCorrect: false },
						{ answerText: 'C) Libre, estatua', isCorrect: false },
						{ answerText: 'D) Música, artista', isCorrect: true },
					],
				},
				{
					questionText: '.... es a tuerca lo que botón es a ....',
					answerOptions: [
						{ answerText: 'A) Puerta, redondo', isCorrect: false },
						{ answerText: 'B) Bisagra, flor', isCorrect: false },
						{ answerText: 'C) Tornillo, ojal', isCorrect: true },
						{ answerText: 'D) Ángulo, saco', isCorrect: false },
					],
				},
				{
					questionText: '.... es a caballo lo que rebuzno es a ....',
					answerOptions: [
						{ answerText: 'A) Guiar, relevo', isCorrect: false },
						{ answerText: 'B) Casco, jaca', isCorrect: false },
						{ answerText: 'C) Relincho, carretón', isCorrect: true },
						{ answerText: 'D) Montura, asno', isCorrect: false },
					],
				},
				{
					questionText: '.... es a nunca lo que todo es a ....',
					answerOptions: [
						{ answerText: 'A) Siempre, nada', isCorrect: true },
						{ answerText: 'B) Ordinariamente, entero', isCorrect: false },
						{ answerText: 'C) Rara vez, cada', isCorrect: false },
						{ answerText: 'D) Frecuentemente, total', isCorrect: false },
					],
				},
				{
					questionText: '.... es a caballería lo que pie es a ....',
					answerOptions: [
						{ answerText: 'A) Caballo, vara', isCorrect: true },
						{ answerText: 'B) Cementerio, viaje', isCorrect: false },
						{ answerText: 'C) Votivo, arsenal', isCorrect: false },
						{ answerText: 'D) Retiro, infanteria', isCorrect: false },
					],
				},
				{
					questionText: '.... es a cumbre lo que base es a ....',
					answerOptions: [
						{ answerText: 'A) Índice, bajo', isCorrect: false },
						{ answerText: 'B) Viento, pelota', isCorrect: false },
						{ answerText: 'C) Lado, cimiento', isCorrect: false },
						{ answerText: 'D) Cuspide, militar', isCorrect: true },
					],
				},
				{
					questionText: '.... es a calle lo que Av. es a ....',
					answerOptions: [
						{ answerText: 'A) 3a, , ciudad', isCorrect: false },
						{ answerText: 'B) Ma. , Francia', isCorrect: false },
						{ answerText: 'C) C. , fin', isCorrect: false },
						{ answerText: 'D) Op. , avenida', isCorrect: true },
					],
				},
				{
					questionText: '.... es a contralto lo que tenor es a ....',
					answerOptions: [
						{ answerText: 'A) Cantor, partitura', isCorrect: false },
						{ answerText: 'B) Soprano, cancíon', isCorrect: false },
						{ answerText: 'C) Sonata, orquesta', isCorrect: false },
						{ answerText: 'D) Solo, baritono', isCorrect: true },
					],
				},
				{
					questionText: '.... es a longitud lo que kilogramo es a ....',
					answerOptions: [
						{ answerText: 'A) Lejos, pesado', isCorrect: true },
						{ answerText: 'B) Metro, onza', isCorrect: false },
						{ answerText: 'C) Europa, peso', isCorrect: false },
						{ answerText: 'D) Viaje, libre', isCorrect: false },
					],
				},
				{
					questionText: '.... es a disputar lo que perdurar es a ....',
					answerOptions: [
						{ answerText: 'A) Imputar, subsistir', isCorrect: true },
						{ answerText: 'B) Reputar, verdura', isCorrect: false },
						{ answerText: 'C) Discutir, perder', isCorrect: false },
						{ answerText: 'D) Dispar, perdonar', isCorrect: false },
					],
				},
				{
					questionText: '.... es a pescar lo que escopeta es a ....',
					answerOptions: [
						{ answerText: 'A) Bacalao, cazar', isCorrect: false },
						{ answerText: 'B) Cebo, grillo', isCorrect: false },
						{ answerText: 'C) Caña, tiro', isCorrect: true },
						{ answerText: 'D) Freir, bala', isCorrect: false },
					],
				},
				{
					questionText: '.... es a Venezuela lo que Habana es a ....',
					answerOptions: [
						{ answerText: 'A) Barranquilla, Puerto Rico', isCorrect: false },
						{ answerText: 'B) Bogotá, Cuba', isCorrect: false },
						{ answerText: 'C) Caracas, México', isCorrect: true },
						{ answerText: 'D) Bolivar, Floridad', isCorrect: false },
					],
				},
				{
					questionText: '.... es a guisante lo que concha es a ....',
					answerOptions: [
						{ answerText: 'A) Verde, acústica', isCorrect: false },
						{ answerText: 'B) Barrer, ostra', isCorrect: false },
						{ answerText: 'C) Vaina, romper', isCorrect: false },
						{ answerText: 'D) Sopa, cáscara', isCorrect: true },
					],
				},
				{
					questionText: '.... es a río lo que costa es a ....',
					answerOptions: [
						{ answerText: 'A) Inundación, playa', isCorrect: true },
						{ answerText: 'B) Lancha, balneario', isCorrect: false },
						{ answerText: 'C) Ribera, mar', isCorrect: false },
						{ answerText: 'D) Marea, malecón', isCorrect: false },
					],
				},
				{
					questionText: '.... es a diestro lo que chambón es a ....',
					answerOptions: [
						{ answerText: 'A) Torpe, experto', isCorrect: false },
						{ answerText: 'B) Habilidad, estúpido', isCorrect: true },
						{ answerText: 'C) Éxito, feo', isCorrect: false },
						{ answerText: 'D) Ángulo, siniestro', isCorrect: false },
					],
				},
				{
					questionText: '.... es a estático lo que dinámico es a ....',
					answerOptions: [
						{ answerText: 'A) Radio, orador', isCorrect: false },
						{ answerText: 'B) Politico, motor', isCorrect: false },
						{ answerText: 'C) Inerte, activo', isCorrect: true },
						{ answerText: 'D) Aire, antena', isCorrect: false },
					],
				},
				{
					questionText: '.... es a pacifista lo que religión es a ....',
					answerOptions: [
						{ answerText: 'A) Guerra, devoto', isCorrect: false },
						{ answerText: 'B) Atlante, estúpido', isCorrect: false },
						{ answerText: 'C) Oponerse, ateo', isCorrect: false },
						{ answerText: 'D) Conciencia, sacerdote', isCorrect: true },
					],
				},
				{
					questionText: '.... es a decolorar lo que ruborizar es a ....',
					answerOptions: [
						{ answerText: 'A) Pintar, abochornar', isCorrect: true },
						{ answerText: 'B) Alegre, calentar', isCorrect: false },
						{ answerText: 'C) Adorno, palidecer', isCorrect: false },
						{ answerText: 'D) Compositor, maquillar', isCorrect: false },
					],
				},
				{
					questionText: '.... es a mar lo que general es a ....',
					answerOptions: [
						{ answerText: 'A) Almirante, revuelta', isCorrect: false },
						{ answerText: 'B) Armada, guerra', isCorrect: false },
						{ answerText: 'C) Marinero, tierra', isCorrect: false },
						{ answerText: 'D) Río, soldado', isCorrect: true },
					],
				},
				{
					questionText: '.... es a avanzar lo que parar es a ....',
					answerOptions: [
						{ answerText: 'A) Aprovechar, impedir', isCorrect: true },
						{ answerText: 'B) Detenerse, pared', isCorrect: false },
						{ answerText: 'C) Retroceder, parado', isCorrect: false },
						{ answerText: 'D) Alcanzar, marchar', isCorrect: false },
					],
				},
				{
					questionText: '.... es a oración lo que oración es a ....',
					answerOptions: [
						{ answerText: 'A) Iglesia, rezo', isCorrect: true },
						{ answerText: 'B) Palabra, coma', isCorrect: false },
						{ answerText: 'C) Punto, párrafo', isCorrect: false },
						{ answerText: 'D) Pregunta, frase', isCorrect: false },
					],
				},
				{
					questionText: '.... es a lluvia lo que dique es a ....',
					answerOptions: [
						{ answerText: 'A) Nube, refrán', isCorrect: false },
						{ answerText: 'B) Niebla, inundacion', isCorrect: false },
						{ answerText: 'C) Agua, subir', isCorrect: true },
						{ answerText: 'D) Paraguas, lavar', isCorrect: false },
					],
				},
				{
					questionText: '.... es a pie lo que codo es a ....',
					answerOptions: [
						{ answerText: 'A) Hombre, mano', isCorrect: false },
						{ answerText: 'B) Muslo, pulgar', isCorrect: false },
						{ answerText: 'C) Rodilla, hombro', isCorrect: true },
						{ answerText: 'C) Talón, dedo', isCorrect: false },
					],
				},
				{
					questionText: '.... es a grupo lo que parte es a ....',
					answerOptions: [
						{ answerText: 'A) Individuo, todo', isCorrect: true },
						{ answerText: 'B) Derecho, separado', isCorrect: false },
						{ answerText: 'C) Ninguno, máximo', isCorrect: false },
						{ answerText: 'D) Lleno, muchote', isCorrect: false },
					],
				},
				{
					questionText: '.... es a papa lo que batidor es a ....',
					answerOptions: [
						{ answerText: 'A) Machucador, arzobispo', isCorrect: false },
						{ answerText: 'B) Puré, batuta', isCorrect: false },
						{ answerText: 'C) Cáscara, bastidor', isCorrect: false },
						{ answerText: 'D) Patata, huevo', isCorrect: true },
					],
				},
				{
					questionText: '.... es a futuro lo que remordimiento es a ....',
					answerOptions: [
						{ answerText: 'A) Adelante, pasado', isCorrect: true },
						{ answerText: 'B) Oportunidad, expirar', isCorrect: false },
						{ answerText: 'C) Pronóstico, ausente', isCorrect: false },
						{ answerText: 'D) Esperanza, pecados', isCorrect: false },
					],
				},
				{
					questionText: '.... es a Inglaterra lo que lira es a ....',
					answerOptions: [
						{ answerText: 'A) Londres, Italia', isCorrect: true },
						{ answerText: 'B) Libra, México', isCorrect: false },
						{ answerText: 'C) Rey, mandolina', isCorrect: false },
						{ answerText: 'D) Colonia, dinero', isCorrect: false },
					],
				},
				{
					questionText: '.... es a colmillo lo que venado es a ....',
					answerOptions: [
						{ answerText: 'A) Marfil, gamo', isCorrect: true },
						{ answerText: 'B) Colmo, caza', isCorrect: false },
						{ answerText: 'C) Elefante, danta', isCorrect: false },
						{ answerText: 'D) Trompas, astas', isCorrect: false },
					],
				},
				{
					questionText: '.... es a tierra lo que nudo es a ....',
					answerOptions: [
						{ answerText: 'A) Desierto, cuerda', isCorrect: true },
						{ answerText: 'B) Kilómetro, yarda', isCorrect: false },
						{ answerText: 'C) Hectárea, mar', isCorrect: false },
						{ answerText: 'D) Granja, montaña', isCorrect: false },
					],
				},
				{
					questionText: '.... es a rombo lo que circulo es a ....',
					answerOptions: [
						{ answerText: 'A) Cuadrado, triángulo', isCorrect: false },
						{ answerText: 'B) Forma, elipse', isCorrect: false },
						{ answerText: 'C) Cubo, redondo', isCorrect: true },
						{ answerText: 'D) Ruta, liso', isCorrect: false },
					],
				},
				{
					questionText: '.... es a día lo que calendario es a ....',
					answerOptions: [
						{ answerText: 'A) Mañana, año', isCorrect: true },
						{ answerText: 'B) Reloj, navidad', isCorrect: false },
						{ answerText: 'C) Sol, marzo', isCorrect: false },
						{ answerText: 'D) Noche, siglo', isCorrect: false },
					],
				},
				{
					questionText: '.... es a cadena lo que cuenta es a ....',
					answerOptions: [
						{ answerText: 'A) Reloj, perla', isCorrect: false },
						{ answerText: 'B) Hierro, cuenca', isCorrect: false },
						{ answerText: 'C) Tirar, rosario', isCorrect: false },
						{ answerText: 'D) Eslabón, contar', isCorrect: true },
					],
				},
				{
					questionText: '.... es a ciudad lo que nacional es a ....',
					answerOptions: [
						{ answerText: 'A) Alcalde, pais', isCorrect: false },
						{ answerText: 'B) Barcelona, federal', isCorrect: true },
						{ answerText: 'C) Límites, gobierno', isCorrect: false },
						{ answerText: 'D) Municipal, internacional', isCorrect: false },
					],
				},
				{
					questionText: '.... es a pájaro lo que mudar es a ....',
					answerOptions: [
						{ answerText: 'A) Cantar, cambio', isCorrect: true },
						{ answerText: 'B) Volar, serpiente', isCorrect: false },
						{ answerText: 'C) Migración, callar', isCorrect: false },
						{ answerText: 'D) Pelechar, voz', isCorrect: false },
					],
				},
				{
					questionText: '.... es a fruta lo que carne es a ....',
					answerOptions: [
						{ answerText: 'A) Pulpa, tajada', isCorrect: false },
						{ answerText: 'B) Mango, hueso', isCorrect: true },
						{ answerText: 'C) Flor, chuleta', isCorrect: false },
						{ answerText: 'D) Canasto, animal', isCorrect: false },
					],
				},
				{
					questionText: '.... es a águila lo que chihuahua es a ....',
					answerOptions: [
						{ answerText: 'A) Bandera, mexicano', isCorrect: true },
						{ answerText: 'B) Colibrí, galgo', isCorrect: false },
						{ answerText: 'C) Moneda, peso', isCorrect: false },
						{ answerText: 'D) Cóndor, sanate', isCorrect: false },
					],
				},
				{
					questionText: '.... es a árbol lo que melón es a ....',
					answerOptions: [
						{ answerText: 'A) Arbusto, rastrera', isCorrect: false },
						{ answerText: 'B) Roblé, jugo', isCorrect: true },
						{ answerText: 'C) Nudoso, maduro', isCorrect: false },
						{ answerText: 'D) Manzana, dulce', isCorrect: false },
					],
				},
				{
					questionText: '.... es a médico lo que secretaria es a ....',
					answerOptions: [
						{ answerText: 'A) Hospital, oficina', isCorrect: false },
						{ answerText: 'B) Doctor, taquigrafa', isCorrect: true },
						{ answerText: 'C) Enfermera, empleado', isCorrect: false },
						{ answerText: 'D) Medicina, jefe', isCorrect: false },
					],
				},
				{
					questionText: '.... es a prisión lo que Louvre es a ....',
					answerOptions: [
						{ answerText: 'A) Carcelero, Francia', isCorrect: true },
						{ answerText: 'B) Bastilla, museo', isCorrect: false },
						{ answerText: 'C) Crimen, amante', isCorrect: false },
						{ answerText: 'D) Barrotes, artista', isCorrect: false },
					],
				},
				{
					questionText: '.... es a libro lo que acto es a ....',
					answerOptions: [
						{ answerText: 'A) Elogio, música', isCorrect: false },
						{ answerText: 'B) Libreto, partitura', isCorrect: false },
						{ answerText: 'C) Capítulo, drama', isCorrect: true },
						{ answerText: 'D) Epílogo, ensayo', isCorrect: false },
					],
				},
				{
					questionText: '.... es a estante lo que peldaño es a ....',
					answerOptions: [
						{ answerText: 'A) Biblioteca, alumninio', isCorrect: false },
						{ answerText: 'B) Libro, escalera', isCorrect: true },
						{ answerText: 'C) Madera, pintura', isCorrect: false },
						{ answerText: 'D) Anaquel, pie', isCorrect: false },
					],
				},
				{
					questionText: '.... es a ópera lo que lírica es a ....',
					answerOptions: [
						{ answerText: 'A) Barítono, sinfonía', isCorrect: false },
						{ answerText: 'B) Drama, música', isCorrect: false },
						{ answerText: 'C) Verdi, himno', isCorrect: true },
						{ answerText: 'D) Compositor, alegre', isCorrect: false },
					],
				},
			],
        };
    },
    methods: {
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
            }
        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        },
        async submitAnswers() {
            let usuario = localStorage.getItem('usuario');

            // Limpiamos el array de respuestas correctas seleccionadas
            this.correctAnswersSelected = [];

            // Recorremos las respuestas seleccionadas y verificamos si son correctas
            this.selectedAnswers.forEach((selectedAnswer, questionIndex) => {
                const correctAnswer = this.questions[questionIndex].answerOptions.find(
                    (answer) => answer.answerText === selectedAnswer && answer.isCorrect
                );

                // Si es correcta, la agregamos al array
                if (correctAnswer) {
                    this.correctAnswersSelected.push(correctAnswer);
                }
            });

            this.correctScore = this.correctAnswersSelected.length;
            this.calificacionPorcentaje = ((this.correctScore / this.maxScore) * 100).toFixed(1);

            const data = {
                idResultado: 0,
                idUsuario: usuario,
                resultadoTestMatematico: "",
                resultadoTestEspanol: this.calificacionPorcentaje.toString(),
                resultadoTest1: { content: "" },
                resultadoTest2: { content: "" },
                resultadoTest3: { content: "" }
            };

            try {
                const response = await axios.post('https://www.vocatecnm-api.somee.com/resultado', data);
                // Manejar respuesta exitosa
                localStorage.removeItem('resultado-lectora');
                localStorage.setItem('resultado-lectora', response.data.resultadoTestEspanol);
                // Redireccionamiento de ruta
                const redirectUrl = this.$route.query.redirect || '/test/academico';
                this.$router.push(redirectUrl);

            } catch (error) {
                console.error('Error:', error);
                // Manejar error
            }
        },
    },
    computed: {
        isLastQuestion() {
            return this.currentQuestionIndex === this.questions.length - 1;
        },
        allQuestionsAnswered() {
            return this.selectedAnswers.length === this.questions.length;
        },
    },
};
</script>

<style scoped>
.content-principal {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    /* Centra verticalmente */
    justify-content: center;
    /* Centra horizontalmente */
}

.quiz-container {
    padding: 20px 20px;
    /* Ajusta el espaciado interno */
    display: flex;
    flex-direction: column;
    /* Alinea los elementos en columna */
    align-items: center;
    /* Centra horizontalmente */
    justify-content: center;
    /* Centra verticalmente */
    margin-top: 100px;
    /* Ajusta el margen superior */
    background-color: #F2F2F2;
    border-radius: 10px;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    width: 900px;
    /* Ajusta el ancho */
    height: 250px;
}

.question-text {
    margin-bottom: 16px;
    font-size: 18px;
    /* Tamaño de fuente de la pregunta */
    font-weight: bold;
    /* Negrita para la pregunta */
    text-align: center;
    /* Centra el texto de la pregunta */
}

.answer-option {
    display: flex;
    /* Coloca el label y el input en una fila */
    align-items: center;
    /* Alinea verticalmente los elementos dentro del contenedor */
    margin-bottom: 12px;
    /* Espaciado entre las opciones de respuesta */
}

.answer-label {
    display: flex;
    /* Coloca el input y el texto en una fila dentro del label */
    align-items: center;
    /* Alinea verticalmente el input y el texto */
    margin-right: 20px;
    /* Espaciado entre cada conjunto de radio button y texto */
    white-space: nowrap;
    /* Evita que el texto se ajuste y haga saltos de línea */
    overflow: hidden;
    /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis;
    /* Añade puntos suspensivos si el texto es demasiado largo */
}

input[type="radio"] {
    margin-right: 8px;
    /* Espaciado entre el radio button y el texto */
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    /* Espaciado superior para los botones de navegación */
    gap: 12px;
    /* Espaciado entre los botones */
}

.navigation-buttons button {
    background-color: #007BFF;
    /* Color de fondo */
    color: #fff;
    /* Color del texto */
    border: none;
    /* Sin borde */
    border-radius: 5px;
    /* Bordes redondeados */
    padding: 10px 20px;
    /* Espaciado interno */
    font-size: 16px;
    /* Tamaño del texto */
    cursor: pointer;
    /* Cursor de puntero */
    transition: background-color 0.3s;
    /* Transición para el cambio de color */
}

.navigation-buttons button:hover {
    background-color: #0056b3;
    /* Color de fondo en hover */
}

.navigation-buttons button:disabled {
    background-color: #d6d6d6;
    /* Color de fondo para botón deshabilitado */
    cursor: not-allowed;
    /* Cursor de no permitido */
}
</style>
