<template>
    <div class="dashboard" style="user-select: none;">
      <nav class="sidebar">
        <ul>
          <div>
            <img :src="img1" width="200" height="150" alt="" class="img-cover">
            <h1 class="titulo-vocatec">VocaTecnm</h1>
          </div>
  
          <div class="enlace-test">
            <li><router-link to="/test/academico" exact-active-class="active">Test Académico</router-link></li>
            <li><router-link to="/test/personal" exact-active-class="active">Test Personal</router-link></li>
            <li><router-link to="/test/resultados" exact-active-class="active">Resultados</router-link></li>
          </div>
          
          <div class="enlace-sesion">
            <li @click.prevent="logout"><router-link to="/" exact-active-class="active">Cerrar Sesión</router-link></li>
          </div>
        </ul>
      </nav>
      <main class="content">
        <router-view></router-view>
      </main>
    </div>
  </template>
  
  <script>
  import img1 from '@/assets/img/hero-slide-1.png'
  
  export default {
    data() {
      return {
        img1,
      }
    },
    methods: {
      logout() {
        localStorage.removeItem('usuario');
        localStorage.removeItem('token');
        localStorage.removeItem('score-test-aptitudes');
        localStorage.removeItem('resultado-intereses-vocacionales');
        localStorage.removeItem('resultado-holland');
        localStorage.removeItem('resultado-lectora');
        localStorage.removeItem('score-test-intereses-vocacionales');
        localStorage.removeItem('score-test-holland');
        localStorage.removeItem('resultado-aptitudes');
        localStorage.removeItem('resultado-matematico');
        this.$router.push('/');
      }
    }
  }
  </script>
  
  <style scoped>
  .dashboard {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    background: linear-gradient(270deg, #0F4392 0%, #001D3D 100%);
    padding: 20px;
    height: 100vh;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar li {
    margin-bottom: 10px;
  }
  
  .sidebar a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.8rem;
  }
  
  .sidebar a .material-icons {
    margin-right: 10px;
  }
  
  .sidebar a.active {
    background-color: #0056b3;
    /* Puedes ajustar este color según sea necesario */
  }
  
  .content {
    flex: 1;
    padding: 20px;
    background: #fff;
    border-left: 1px solid #ddd;
  }
  
  .titulo-vocatec {
    color: #fff;
    text-align: center;
    font-size: 2.5rem;
  }
  
  .enlace-test {
    margin-top: 60%;
  }
  
  .enlace-sesion {
    margin-top: 95%;
  }
  
  .enlace-sesion:hover {
    background: linear-gradient(270deg, #0b4498 0%, rgb(1, 64, 131) 100%);
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      width: 180px;
      padding: 10px;
    }
  
    .sidebar .img-cover {
      width: 150px;
      height: 100px;
    }
  
    .titulo-vocatec {
      font-size: 1.8rem;
    }
  
    .sidebar a {
      font-size: 1.4rem;
      padding: 8px;
    }
  
    .enlace-test {
      margin-top: 50%;
    }
  
    .enlace-sesion {
      margin-top: 85%;
    }
  }
  
  @media (max-width: 480px) {
    .sidebar {
      width: 150px;
      padding: 5px;
    }
  
    .sidebar .img-cover {
      width: 100px;
      height: 75px;
    }
  
    .titulo-vocatec {
      font-size: 1.5rem;
    }
  
    .sidebar a {
      font-size: 1.2rem;
      padding: 5px;
    }
  
    .enlace-test {
      margin-top: 40%;
    }
  
    .enlace-sesion {
      margin-top: 75%;
    }
  }
  </style>
  