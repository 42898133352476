<template>
    <div class="parent">
        <div class="div1">
            <div class="card-container">
                <!-- Usa el componente apexchart con las opciones y serie definidas -->
                <apexchart type="radialBar" :options="chartOptions" :series="chartSeries" height="350" />
            </div>
        </div>
        <div class="div2">
            <div class="card-container">
                <!-- Puedes agregar más gráficos u otros contenidos aquí -->
                <apexchart type="radialBar" :options="chartOptions2" :series="chartSeries2" height="350" />
            </div>
        </div>
        <div class="div3">
            <div class="card-container">
                <!-- Puedes agregar más gráficos u otros contenidos aquí -->
                <span class="titulos" style="font-weight: 600;"> Autoevaluación de Aptitudes</span>
                <apexchart type="bar" :options="chartOptions3" :series="chartSeries3" height="350" />
            </div>
        </div>
        <div class="div4">
            <div class="card-container">
                <!-- Puedes agregar más gráficos u otros contenidos aquí -->
                <span class="titulos" style="font-weight: 600;"> Test de Holland</span>
                <apexchart type="bar" :options="chartOptions4" :series="chartSeries4" height="350" />
            </div>
        </div>
        <div class="div5">
            <div class="card-container">
                <!-- Puedes agregar más gráficos u otros contenidos aquí -->
                <span class="titulos" style="font-weight: 600;"> Identificación de Intereses Vocacionales</span>
                <apexchart type="bar" :options="chartOptions5" :series="chartSeries5" height="350"></apexchart>
            </div>
        </div>
        <div class="div6">
            <div class="card-container">
                <!-- Puedes agregar más gráficos u otros contenidos aquí -->
                <h1 class="titulos">{{ resultadoEvaluacion }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ApexCharts from 'vue3-apexcharts';

export default defineComponent({
    components: {
        apexchart: ApexCharts,
    },
    data() {

        /* Test Academicos */
        const resultadoMatematico = localStorage.getItem('resultado-matematico') || 0;
        const resultadoLectora = localStorage.getItem('resultado-lectora') || 0;

        /*Test de aptitudes*/
        // Obtén el resultado de localStorage y divídelo en un array
        const resultados = localStorage.getItem('resultado-aptitudes') || '';
        const labelsArray = resultados.split(',').map(item => item.trim());

        // Asegúrate de que labelsArray tenga al menos 5 elementos, o ajusta manualmente
        if (labelsArray.length < 5) {
            labelsArray.push('Elemento 5'); // Agrega un valor predeterminado si es necesario
        }

        // Obtén los valores de localStorage y conviértelos en un array de números
        const scoreString = localStorage.getItem('score-test-aptitudes');
        const scoreArray = scoreString ? scoreString.split(',').map(Number) : [];

        // Verifica que tienes 5 valores antes de actualizar chartSeries3
        const updatedChartSeries3 = scoreArray.length === 5 ? scoreArray : [0, 0, 0, 0, 0]; // Puedes usar valores predeterminados si no hay suficientes datos

        /*Test de holland */
        // Obtén el resultado de localStorage y divídelo en un array
        const resultadosH = localStorage.getItem('resultado-holland') || '';
        const labelsArrayH = resultadosH.split(',').map(item => item.trim());

        // Asegúrate de que labelsArray tenga al menos 3 elementos, o ajusta manualmente
        if (labelsArrayH.length < 3) {
            labelsArrayH.push('Elemento 3'); // Agrega un valor predeterminado si es necesario
        }

        // Obtén los valores de localStorage y conviértelos en un array de números
        const scoreStringH = localStorage.getItem('score-test-holland');
        const scoreArrayH = scoreStringH ? scoreStringH.split(',').map(Number) : [];

        // Verifica que tienes 5 valores antes de actualizar chartSeries3
        const updatedChartSeries4 = scoreArrayH.length === 3 ? scoreArrayH : [0, 0, 0]; // Puedes usar valores predeterminados si no hay suficientes datos

        //Test de Intereses vocacionales
        // Obtener los resultados y convertir las etiquetas
        const resultadosI = localStorage.getItem('resultado-intereses-vocacionales') || '';
        const labelsArrayI = resultadosI.split(',').map(item => item.trim());
        labelsArrayI.pop();

        // Asegúrate de que labelsArray tenga al menos 3 elementos, o ajusta manualmente
        if (labelsArrayI.length < 3) {
            labelsArrayI.push('Elemento 3'); // Agrega un valor predeterminado si es necesario
        }

        // Obtener los valores de localStorage y convertirlos a números
        const scoreStringI = localStorage.getItem('score-test-intereses-vocacionales');
        const scoreArrayI = scoreStringI ? scoreStringI.split(',') : [];

        // Verifica que tienes el número adecuado de valores y ajusta si es necesario
        const updatedChartSeries5 = scoreArrayI.length === labelsArrayI.length ? scoreArrayI : new Array(labelsArrayI.length).fill(0); // Usa 0 si faltan datos

        return {
            resultadoEvaluacion: '',// Propiedad para almacenar el resultado
            // Gráfica 1
            chartOptions: {
                series: [70],
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '65%',
                        },
                        track: {
                            background: '#ffffff', // Establece el fondo del círculo en color blanco
                            strokeWidth: 'thin', // Ajusta el grosor del track
                        },
                    },
                },
                labels: ['Habilidad Numérica'],
            },
            chartSeries: [resultadoMatematico],

            // Gráfica 2
            chartOptions2: {
                series: [70],
                chart: {
                    height: 300,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '65%',
                        },
                        track: {
                            background: '#ffffff', // Establece el fondo del círculo en color blanco
                            strokeWidth: 'thin', // Ajusta el grosor del track
                        },
                    },
                },
                labels: ['Habilidad Lectora'],
            },
            chartSeries2: [resultadoLectora],

            // Gráfica 3 (Barra horizontal)
            chartOptions3: {
                series: [{
                    data: updatedChartSeries3, // Usa los valores actualizados de la gráfica 3
                }],
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false // Desactiva el toolbar que incluye el botón de exportación
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                        barHeight: '50%' // Ajusta el grosor de las barras (puedes reducir o aumentar este valor)
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: labelsArray.slice(0, 5), // Usa labelsArray para las categorías
                }
            },
            chartSeries3: [{
                data: updatedChartSeries3, // Usa los valores actualizados de la gráfica 3
            }],

            //Grafica 4
            chartOptions4: {
                series: [{
                    data: updatedChartSeries4, // Usa los valores actualizados de la gráfica 3
                }],
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false // Desactiva el toolbar que incluye el botón de exportación
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                        barHeight: '30%' // Ajusta el grosor de las barras (puedes reducir o aumentar este valor)
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: labelsArrayH.slice(0, 3), // Usa labelsArray para las categorías
                }
            },
            chartSeries4: [{
                data: updatedChartSeries4, // Usa los valores actualizados de la gráfica 3
            }],

            //Grafica 5
            chartOptions5: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false // Desactiva el toolbar que incluye el botón de exportación
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: labelsArrayI, // Actualiza las categorías con datos de localStorage
                },
                yaxis: {
                    title: {
                        text: '%' // Cambia el título del eje Y a '%' ya que los datos están en porcentaje
                    },
                    max: 100 // Establece el máximo valor del eje Y en 100
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " %"; // Ajusta el formato del tooltip para mostrar porcentaje
                        }
                    }
                }
            },
            chartSeries5: [
                {
                    name: 'Scores',
                    data: updatedChartSeries5 // Actualiza los datos de la serie con valores de localStorage
                },
            ],
        };
    },
    mounted() {
        this.evaluarCarreraYMostrar();
    },
    methods: {
        // Define tu método para evaluar la carrera
        evaluarCarreraYMostrar() {
            // Llama a la función que has definido y asigna el resultado

            const resultadoMatematico = localStorage.getItem('resultado-matematico');
            const resultadoEspanol = localStorage.getItem('resultado-lectora');
            const testAptitud = (localStorage.getItem('resultado-aptitudes') || '').split(',').map(item => item.trim());
            const testHolland = (localStorage.getItem('resultado-holland') || '').split(',').map(item => item.trim());
            const testVocacional = (localStorage.getItem('resultado-intereses-vocacionales') || '').split(',').map(item => item.trim())[0];

            // Verificar que los puntajes matemático y español sean mayores o iguales a 60
            if (resultadoMatematico >= 60 && resultadoEspanol >= 60) {
                // Condición para Ingenieria en Sistemas Computacionales
                if (
                    testAptitud.slice(0, 3).includes('Numérica') &&
                    testAptitud.slice(0, 3).includes('Abstracta o Científica') &&
                    testAptitud.slice(0, 3).includes('Coordinación Visomotriz') &&
                    testHolland.slice(0, 3).includes('Realista') &&
                    testHolland.slice(0, 3).includes('Investigador') &&
                    testHolland.slice(0, 3).includes('Artístico') &&
                    testVocacional === 'Ing. Sistemas Computacionales'
                ) {
                    return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería en Sistemas Computacionales.';
                }
                // Condición para Ingenieria en Informática o TIC
                else if (
                    testAptitud.slice(0, 3).includes('Numérica') &&
                    testAptitud.slice(0, 3).includes('Abstracta o Científica') &&
                    testAptitud.slice(0, 3).includes('Coordinación Visomotriz') &&
                    testHolland.slice(0, 3).includes('Investigador') &&
                    testHolland.slice(0, 3).includes('Realista') &&
                    testHolland.slice(0, 3).includes('Artístico')
                ) {
                    if (testVocacional === 'Ing. Informática') {
                        return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería en Informática.';
                    } else if (testVocacional === 'Ing. en Tecnologías de la Información y Comunicación') {
                        return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería en Tecnologías de la Información y Comunicación.';
                    }
                }
                // Condición para Ingenieria Electromecánica
                else if (
                    testAptitud.slice(0, 3).includes('Mecánica') &&
                    testAptitud.slice(0, 3).includes('Numérica') &&
                    testAptitud.slice(0, 3).includes('Coordinación Visomotriz') &&
                    testHolland.slice(0, 3).includes('Realista') &&
                    testHolland.slice(0, 3).includes('Investigador') &&
                    testHolland.slice(0, 3).includes('Convencional') &&
                    testVocacional === 'Ing. Electromecánica'
                ) {
                    return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería electromecánica.';
                }
                // Condición para Ingenieria Industrial
                else if (
                    testAptitud.slice(0, 3).includes('Mecánica') &&
                    testAptitud.slice(0, 3).includes('Numérica') &&
                    testAptitud.slice(0, 3).includes('Coordinación Visomotriz') &&
                    testHolland.slice(0, 3).includes('Realista') &&
                    testHolland.slice(0, 3).includes('Investigador') &&
                    testHolland.slice(0, 3).includes('Emprendedor') &&
                    testVocacional === 'Ing. Industrial'
                ) {
                    return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería industrial.';
                }
                // Condición para Ingenieria Bioquímica
                else if (
                    testAptitud.slice(0, 3).includes('Abstracta o Científica') &&
                    testAptitud.slice(0, 3).includes('Numérica') &&
                    testAptitud.slice(0, 3).includes('Verbal') &&
                    testHolland.slice(0, 3).includes('Investigador') &&
                    testHolland.slice(0, 3).includes('Realista') &&
                    testHolland.slice(0, 3).includes('Convencional') &&
                    testVocacional === 'Ing. Bioquímica'
                ) {
                    return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería bioquímica.';
                }
                // Condición para Ingenieria Mecánica
                else if (
                    testAptitud.slice(0, 3).includes('Mecánica') &&
                    testAptitud.slice(0, 3).includes('Numérica') &&
                    testAptitud.slice(0, 3).includes('Espacial') &&
                    testHolland.slice(0, 3).includes('Realista') &&
                    testHolland.slice(0, 3).includes('Investigador') &&
                    testHolland.slice(0, 3).includes('Emprendedor') &&
                    testVocacional === 'Ing. Mecánica'
                ) {
                    return this.resultadoEvaluacion = 'Basado con el resultado del sistema experto eres compatible para la ingeniería mecánica.';
                }

                return this.resultadoEvaluacion = 'No se pudo determinar una carrera específica.';
            } else {
                return this.resultadoEvaluacion = 'No se pudo determinar una carrera específica.';
            }

        }
    },
});
</script>

<style scoped>
.parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Tres columnas de igual tamaño */
    grid-template-rows: repeat(3, 1fr);
    /* Tres filas de igual tamaño */
    gap: 10px;
    /* Espacio entre las tarjetas */
    height: 90vh;
    /* Ocupa toda la altura de la ventana gráfica */
    padding: 10px;
    /* Asegura un margen interno en el contenedor */
    box-sizing: border-box;
    /* Incluye el padding en el tamaño total */
}

.div1 {
    grid-column: 1 / 2;
    /* Ocupa la primera columna */
    grid-row: 1 / 2;
    /* Ocupa la primera fila */
}

.div2 {
    grid-column: 2 / 3;
    /* Ocupa la segunda columna */
    grid-row: 1 / 2;
    /* Ocupa la primera fila */
}

.div3 {
    grid-column: 3 / 4;
    /* Ocupa la tercera columna */
    grid-row: 1 / 2;
    /* Ocupa la primera fila */
}

.div4 {
    grid-column: 1 / 2;
    /* Ocupa la primera columna */
    grid-row: 2 / 3;
    /* Ocupa la segunda fila */
}

.div5 {
    grid-column: 2 / 3;
    /* Ocupa la segunda columna */
    grid-row: 2 / 3;
    /* Ocupa la segunda fila */
}

.div6 {
    grid-column: 3 / 4;
    /* Ocupa la tercera columna */
    grid-row: 2 / 3;
    /* Ocupa la segunda fila */
}

.div7 {
    grid-column: 1 / 4;
    /* Ocupa las tres columnas */
    grid-row: 3 / 4;
    /* Ocupa la tercera fila */
}



.card-container {
    background-color: transparent;
    border-radius: 10px;
    max-width: 450px;
    /* Ancho máximo de las tarjetas */
    max-height: 320px;
    /* Altura máxima de las tarjetas */
    display: grid;
    place-items: center;
    /* Centra el contenido dentro de la tarjeta 
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    */
    width: 100%;
    /* Hace que el ancho de la tarjeta se ajuste al contenedor */
    height: 100%;
    /* Hace que la altura de la tarjeta se ajuste al contenedor */
    box-sizing: border-box;
    /* Incluye el padding en el tamaño total */
    margin-top: 20px;
}

.titulos{
    color: #26a0fc;
    text-transform: uppercase;
    font-size: 24px;
}
</style>
