import { createRouter, createWebHistory } from "vue-router"
import Home from '@/views/Home.vue'
import InicioDeSesion from '@/views/InicioSesionRegistro.vue'
import TestAcademico from '@/views/TestAcademico.vue'
import TestPersonal from "@/views/TestPersonal.vue"
import TestResultados from "@/views/TestResultados.vue"
import Navegation from "@/components/Navegation.vue"
import TestMatematico from "@/views/test/TestMatematico.vue"
import TestLectora from "@/views/test/TestLectora.vue"
import TestAptitudes from "@/views/test/TestAptitudes.vue"
import TestHolland from "@/views/test/TestHolland.vue"
import TestIntereses from "@/views/test/TestIntereses.vue"

const routes = [
  {
    path: '/',
    component: Home,
    beforeEnter: (to, from, next) => {
      const usuario = localStorage.getItem('usuario');
      const token = localStorage.getItem('token')
      if (usuario && token) {
        next('/test');
      } else {
        next();
      }
    }
  },

  {
    path: '/acceso/:view?',
    component: InicioDeSesion,
    props: route => ({ view: route.params.view }),
    beforeEnter: (to, from, next) => {
      const usuario = localStorage.getItem('usuario');
      const token = localStorage.getItem('token')
      if (usuario && token) {
        next('/test');
      } else {
        next();
      }
    }
  },

  {
    path: '/test',
    component: Navegation,
    children: [
      { path: '', component: TestPersonal },
      { path: 'personal', component: TestPersonal },
      { path: 'academico', component: TestAcademico },
      { path: 'resultados', component: TestResultados },
      { path: 'habilidad-numerica', component: TestMatematico },
      { path: 'habilidad-lectora', component: TestLectora },
      { path: 'autoevaluacion-aptitudes', component: TestAptitudes },
      { path: 'holland', component: TestHolland },
      { path: 'identificacion-intereses-vocacionales', component: TestIntereses },

    ], beforeEnter: (to, from, next) => {
      const usuario = localStorage.getItem('usuario');
      const token = localStorage.getItem('token')
      if (usuario && token) {
        next();
      } else {
        next('/');
      }
    }
  },
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router

